<template>
    <div>
        <Header @refrescar="refrescar"/><br><br><br>
        <v-container
            <template>
                <v-row dense>
                    <v-row><br><br>
            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Precio bolsa
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">${{promediobolsa}}
                  </v-col>
              </v-row>
              <canvas id="bolsa" canvas-height="80"></canvas>
           </v-card>


            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Embalse
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">{{promedioembalse}}%
                  </v-col>
              </v-row>
              <canvas id="embalse" canvas-height="80"></canvas>
           </v-card>


            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Hidrología
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">{{promediohidrologia}}%
                  </v-col>
              </v-row>
              <canvas id="hidrologia" canvas-height="80"></canvas>
           </v-card>


            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Generación
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">{{promediogeneracion+'GWh'}}
                  </v-col>
              </v-row>
              <canvas id="generacion" canvas-height="80"></canvas>
           </v-card>


            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Demanda
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">{{promediodemanda+'GWh'}}
                  </v-col>
              </v-row>
              <canvas id="demanda" canvas-height="80"></canvas>
           </v-card>


            <v-card class="mx-auto mt-1" width="350" elevation="10">
             <v-row align="center">
             <v-col
              cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Exportación
                  </v-list-item-title>
                  <v-list-item-subtitle>{{textomes+' '+textoano}}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-col>
                <v-col
                  cols="6"
                  class="text-h5">{{promedioexportada+'GWh'}}
                  </v-col>
              </v-row>
              <canvas id="exportada" canvas-height="80"></canvas>
           </v-card>

           </v-row> 
        </v-row> 
        </template>
        </v-container fluid>
      </div>
      </template>
<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
   export default {
        name: 'Mercado',
        components:{
        Header
        },
    data() {  
        let colores=['rgba(0,0,255, 1)','rgba(02,71,171, 1)','rgba(18,10,143, 1)'] 
        let fuente=10;
        let variable=7;
        return {
        respuesta:null,
        bolsa:null,
        volumen:null,
        utilizado:null,
        embalse:null,
        aporte:null,
        historico:null,
        hidrologia:null,
        generacion:null,
        demanda:null,
        mercadobolsa : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        embalsetotal : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        hidrologiatotal : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        generaciontotal : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        demandatotal : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        exportadatotal : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,},ticks: {fontSize: fuente}}]}}},
        textomes: "",
        textoano: "",
        textodia: "",
        promediobolsa:0,
        promedioembalse:0,
        promediohidrologia:0,
        promediogeneracion:0,
        promediodemanda:0,
        promedioexportada:0,
        }

    },
        async mounted () 
        {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        var metricid='';
        var sistema='';
        const d=new Date();
        const dia=d.getDate();
        let meses=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        const mes=d.getMonth()+1;
        const ano=d.getFullYear();
        let fdia='';let fmes='';let fechainicial='';let fechafinal='';
        const diam=this.diasmes(mes,ano);
        this.textomes=meses[mes-1];
        this.textoano=ano;
        fdia=String(dia);fmes=String(mes);
        if (dia.toString().length==1){fdia='0'+String(dia);}
        if (mes.toString().length==1){fmes='0'+String(mes);}
        fechainicial=String(ano)+'-'+fmes+'-01';
        fechafinal=String(ano)+'-'+fmes+'-'+String(diam);
        //fechafinal=String(ano)+'-'+fmes+'-01';
            await this.llamarapi('xmh','PrecBolsNaci',fechainicial,fechafinal,'Sistema',token);
            this.bolsa=this.respuesta;
            this.graficar(this.bolsa,1,1);
            await this.llamarapi('xmd','VoluUtilDiarEner',fechainicial,fechafinal,'Sistema',token);
            this.volumen=this.respuesta;
            await this.llamarapi('xmd','CapaUtilDiarEner',fechainicial,fechafinal,'Sistema',token);
            this.capacidad=this.respuesta;
            this.graficar(this.volumen,2,1);
            await this.llamarapi('xmd','AporEner',fechainicial,fechafinal,'Sistema',token);
            this.aporte=this.respuesta;
            await this.llamarapi('xmd','AporEnerMediHist',fechainicial,fechafinal,'Sistema',token);
            this.historico=this.respuesta;
            this.graficar(this.hidrologia,3,1);            
            await this.llamarapi('xmh','Gene',fechainicial,fechafinal,'Sistema',token);
            this.generacion=this.respuesta;
            this.graficar(this.generacion,4,1);            
            await this.llamarapi('xmh','DemaCome',fechainicial,fechafinal,'Sistema',token);
            this.demanda=this.respuesta;
            this.graficar(this.demanda,5,1);            
            await this.llamarapi('xmh','ExpoEner',fechainicial,fechafinal,'Sistema',token);
            this.exportacion=this.respuesta;
            this.graficar(this.exportacion,6,1);            
        },
    methods:{
        llamarapi(api,metricid,fechainicial,fechafinal,sistema,token){
        return new Promise((resolve,reject)=>{
        let json= {
            "MetricId": metricid,
            "StartDate": fechainicial,
            "EndDate": fechafinal,
            "Entity":sistema
        };
        axios.post("https://api.coenergia.app/api/"+api,json,{
          headers: {'Authorization': `Bearer ${token}`}})
        .then(data=>{this.respuesta=data
        resolve();
        });
        });
      },
      graficar(data,funcion,tipo){
            switch (funcion) {
              case 1:
                this.promediobolsa=0;
                var etiqueta='';
                var valor=0;
                if (this.bolsa.data.Items.length>1)
                  {
                    for (var i =0; i < this.bolsa.data.Items.length; i++) 
                      {
                        for (var j =1; j < 25; j++) 
                          { 
                            etiqueta=String(j);
                            if (etiqueta.length==1){etiqueta='0'+etiqueta}
                              valor=(this.bolsa.data.Items[i].HourlyEntities[0].Values['Hour'+etiqueta])
                                if (valor.length==0){valor='0'}
                                  this.promediobolsa=this.promediobolsa+parseFloat(valor);
                          }
                            this.promediobolsa=this.promediobolsa/24;
                            this.mercadobolsa.data.labels.push(String(i+1));
                            this.mercadobolsa.data.datasets[0].data.push(this.promediobolsa);
                            this.mercadobolsa.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                else
                  {
                    for (var j =1; j < 25; j++) 
                      { 
                        etiqueta=String(j);
                        if (etiqueta.length==1){etiqueta='0'+etiqueta}
                          valor=(this.bolsa.data.Items[0].HourlyEntities[0].Values['Hour'+etiqueta])
                        if (valor.length==0){valor='0'}
                          this.promediobolsa=parseFloat(valor);
                          this.mercadobolsa.data.labels.push(String(j));
                          this.mercadobolsa.data.datasets[0].data.push(this.promediobolsa);
                          this.mercadobolsa.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                  for (var i =0; i < this.mercadobolsa.data.datasets[0].data.length; i++)
                   {this.promediobolsa=parseFloat(this.mercadobolsa.data.datasets[0].data[i])+this.promediobolsa;}
                  this.promediobolsa=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promediobolsa/this.mercadobolsa.data.datasets[0].data.length).toFixed(2)));
                  const ctx = document.getElementById('bolsa');new Chart(ctx, this.mercadobolsa);
                break;
              case 2:
                this.promedioembalse=0;
                var etiqueta='';
                var valor1;
                  for (var i =0; i < this.volumen.data.Items.length; i++)
                  {
                      this.embalsetotal.data.labels.push(String(i+1));
                      this.embalsetotal.data.datasets[0].borderColor.push("#1B3AF4");
                      valor1=((parseFloat(this.volumen.data.Items[i].DailyEntities[0].Value)/parseFloat(this.capacidad.data.Items[i].DailyEntities[0].Value))*100)
                      this.embalsetotal.data.datasets[0].data.push(parseFloat(valor1))
                  }
                  for (var i =0; i <this.volumen.data.Items.length; i++)
                   {
                     this.promedioembalse=this.promedioembalse+parseFloat(this.embalsetotal.data.datasets[0].data[i]);
                   }
                  this.promedioembalse=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promedioembalse/ this.volumen.data.Items.length).toFixed(2)));
                  const ctx1 = document.getElementById('embalse');new Chart(ctx1, this.embalsetotal);
                break;
              case 3:
                this.promediohidrologia=0;
                var etiqueta='';
                var valor2;
                  for (var i =0; i < this.aporte.data.Items.length; i++)
                  {
                      this.hidrologiatotal.data.labels.push(String(i+1));
                      this.hidrologiatotal.data.datasets[0].borderColor.push("#1B3AF4");
                      valor2=((parseFloat(this.aporte.data.Items[i].DailyEntities[0].Value)/parseFloat(this.historico.data.Items[i].DailyEntities[0].Value))*100)
                      this.hidrologiatotal.data.datasets[0].data.push(parseFloat(valor2))
                  }
                  for (var i =0; i <this.aporte.data.Items.length; i++)
                   {
                     this.promediohidrologia=this.promediohidrologia+parseFloat(this.hidrologiatotal.data.datasets[0].data[i]);
                   }
                  this.promediohidrologia=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promediohidrologia/ this.aporte.data.Items.length).toFixed(2)));
                  const ctx2 = document.getElementById('hidrologia');new Chart(ctx2, this.hidrologiatotal);
                break;
              case 4:
                this.promediogeneracion=0;
                var etiqueta='';
                var valor3=0;
                if (this.generacion.data.Items.length>1)
                  {
                    for (var i =0; i < this.generacion.data.Items.length; i++) 
                      {
                        this.promediogeneracion=0;
                        for (var j =1; j < 25; j++) 
                          { 
                            etiqueta=String(j);
                            if (etiqueta.length==1){etiqueta='0'+etiqueta}
                              valor3=(this.generacion.data.Items[i].HourlyEntities[0].Values['Hour'+etiqueta])
                                if (valor3.length==0){valor3='0'}
                                  this.promediogeneracion=this.promediogeneracion+parseFloat(valor3)/1000000;
                          }
                            this.promediogeneracion=this.promediogeneracion;
                            this.generaciontotal.data.labels.push(String(i+1));
                            this.generaciontotal.data.datasets[0].data.push(this.promediogeneracion);
                            this.generaciontotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                else
                  {
                    for (var j =1; j < 25; j++) 
                      { 
                        etiqueta=String(j);
                        if (etiqueta.length==1){etiqueta='0'+etiqueta}
                          valor3=(this.generacion.data.Items[0].HourlyEntities[0].Values['Hour'+etiqueta])
                        if (valor3.length==0){valor3='0'}
                          this.promediogeneracion=parseFloat(valor3)/1000000;
                          this.generaciontotal.data.labels.push(String(j));
                          this.generaciontotal.data.datasets[0].data.push(this.promediogeneracion);
                          this.generaciontotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                  for (var i =0; i < this.generaciontotal.data.datasets[0].data.length; i++)
                   {this.promediogeneracion=parseFloat(this.generaciontotal.data.datasets[0].data[i])+this.promediogeneracion;}
                  this.promediogeneracion=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promediogeneracion).toFixed(2)));
                  const ctx3 = document.getElementById('generacion');new Chart(ctx3, this.generaciontotal);
                break;
              case 5:
                this.promediodemanda=0;
                var etiqueta='';
                var valor4=0;
                if (this.demanda.data.Items.length>1)
                  {
                    for (var i =0; i < this.demanda.data.Items.length; i++) 
                      {
                        this.promediodemanda=0;
                        for (var j =1; j < 25; j++) 
                          { 
                            etiqueta=String(j);
                            if (etiqueta.length==1){etiqueta='0'+etiqueta}
                              valor4=(this.demanda.data.Items[i].HourlyEntities[0].Values['Hour'+etiqueta])
                                if (valor4.length==0){valor4='0'}
                                  this.promediodemanda=this.promediodemanda+parseFloat(valor4)/1000000;
                          }
                            this.promediodemanda=this.promediodemanda;
                            this.demandatotal.data.labels.push(String(i+1));
                            this.demandatotal.data.datasets[0].data.push(this.promediodemanda);
                            this.demandatotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                else
                  {
                    for (var j =1; j < 25; j++) 
                      { 
                        etiqueta=String(j);
                        if (etiqueta.length==1){etiqueta='0'+etiqueta}
                          valor4=(this.demanda.data.Items[0].HourlyEntities[0].Values['Hour'+etiqueta])
                        if (valor4.length==0){valor4='0'}
                          this.promediodemanda=parseFloat(valor3)/1000000;
                          this.demandatotal.data.labels.push(String(j));
                          this.demandatotal.data.datasets[0].data.push(this.promediodemanda);
                          this.demandatotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                  for (var i =0; i < this.demandatotal.data.datasets[0].data.length; i++)
                   {this.promediodemanda=parseFloat(this.demandatotal.data.datasets[0].data[i])+this.promediodemanda;}
                  this.promediodemanda=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promediodemanda).toFixed(2)));
                  const ctx4 = document.getElementById('demanda');new Chart(ctx4, this.demandatotal);
                break;
               case 6:
                this.promedioexportada=0;
                var etiqueta='';
                var valor5=0;
                if (this.exportacion.data.Items.length>1)
                  {
                    for (var i =0; i < this.exportacion.data.Items.length; i++) 
                      {
                        this.promedioexportada=0;
                        for (var j =1; j < 25; j++) 
                          { 
                            console.log(j)
                            etiqueta=String(j);
                            if (etiqueta.length==1){etiqueta='0'+etiqueta}
                              valor5=(this.exportacion.data.Items[i].HourlyEntities[0].Values['Hour'+etiqueta])
                                if (valor5.length==0){valor5='0'}
                                  this.promedioexportada=this.promedioexportada+parseFloat(valor5)/1000000;
                          }
                            this.promedioexportada=this.promedioexportada;
                            this.exportadatotal.data.labels.push(String(i+1));
                            this.exportadatotal.data.datasets[0].data.push(this.promedioexportada);
                            this.exportadatotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                else
                  {
                    for (var j =1; j < 25; j++) 
                      { 
                        etiqueta=String(j);
                        if (etiqueta.length==1){etiqueta='0'+etiqueta}
                          valor5=(this.exportada.data.Items[0].HourlyEntities[0].Values['Hour'+etiqueta])
                        if (valor5.length==0){valor5='0'}
                          this.promedioexportada=parseFloat(valor5)/1000000;
                          this.exportadatotal.data.labels.push(String(j));
                          this.exportadatotal.data.datasets[0].data.push(this.promedioexportada);
                          this.exportadatotal.data.datasets[0].borderColor.push("#1B3AF4");
                      }
                  }
                  console.log(this.exportadatotal)
                  for (var i =0; i < this.exportadatotal.data.datasets[0].data.length; i++)
                   {this.promedioexportada=parseFloat(this.exportadatotal.data.datasets[0].data[i])+this.promedioexportada;}
                  this.promedioexportada=(Intl.NumberFormat("es-MX",{ maximumSignificantDigits: 5 }).format((this.promedioexportada).toFixed(2)));
                  const ctx5 = document.getElementById('exportada');new Chart(ctx5, this.exportadatotal);
                break;
                }
      },
            smes(item){
              alert(item);

            },
            sdia(item){
              alert(item);

            },
            diasmes(mes, año) {
              return new Date(año, mes, 0).getDate();
            }
    }
   }
</script>