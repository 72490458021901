import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import AxiosPlugin from 'vue-axios-cors'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
Vue.config.productionTip = false
Vue.use (VueAxios, axios)
Vue.use(AxiosPlugin)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
