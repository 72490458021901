<template>
<div>
        <Headerlogin />
         <v-container fluid>
          <v-row class="mt-15" justify="center"><v-col cols="12" sm="10" md="6" lg="4">
            <v-card ref="form"><v-toolbar dark color="#1b3af4" height="50%"><v-toolbar-title class="text-body-1 white--text">Login</v-toolbar-title></v-toolbar>
              <v-card-text v-show="this.nm"><v-text-field ref="nombre" v-model="nombre" label="nombre" required></v-text-field></v-card-text>
              <v-card-text><v-text-field ref="email" v-model="email" label="email" required></v-text-field></v-card-text>
              <v-card-text v-show="this.mp"><v-text-field type="password" ref="password" v-model="password" label="password" required></v-text-field></v-card-text>
              <v-card-text v-show="this.mp1"><v-text-field type="password" ref="password1" v-model="password1" label="confirmar password" required></v-text-field></v-card-text>
            <v-card-actions>
            <v-btn color="#1b3af4" style="color:white" class="ml-2" @click="login()"><p ref="btn0" class="mt-4">Ingresar</p></v-btn>
            </v-card-actions>
              <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-btn color="primary" text @click="registrarse()"><p ref="btn1">Registrarse</p></v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" disabled text @click="Reset()"><p ref="btn2"></p></v-btn>
            </v-card-actions>
            </v-card>
            </v-col>
            </v-row>
         </v-container>
         <v-dialog v-model="dialog" max-width="290"><v-card><br><v-card-text>{{this.alerttext}}</v-card-text><v-card-actions><v-spacer></v-spacer><v-btn color="green darken-1" text @click="dialog = false">Ok</v-btn></v-card-actions></v-card></v-dialog>
         </div>
</template>

<script>
import axios from 'axios'
import Headerlogin from '@/components/Headerlogin.vue';
  export default {
    name: 'Home',
    components: {
      Headerlogin,
    },
    mounted(){
    },
    data: function(){
      return{nombre:"",email:"",password:"",password1:"",error:false,errorMessages: '',mp:true,nm:false,mp1:false,funcion:0,dialog:false,alerttext:'',}
    },
    methods:{
      login(){
        this.err=false
        if (this.funcion==0)
        {
        if (this.email.length!=0 && this.password.length!=0)
        {
        let json={"email":this.email,"password":this.password};
        axios.post("https://api.coenergia.app/api/login",json)
        .then(data=>{
          localStorage.token=this.$CryptoJS.AES.encrypt(data.data.data.token, "g3st10n3s33n").toString();
          localStorage.usuario=this.$CryptoJS.AES.encrypt(data.data.data.name, "g3st10n3s33n").toString();
          if (data.data.data.frontera==undefined)
          {
          this.alerttext = "Pendiente por asignar permisos"
          this.dialog=true
          }
          else{
          localStorage.frontera=this.$CryptoJS.AES.encrypt(data.data.data.frontera, "g3st10n3s33n").toString();
          localStorage.perfil=this.$CryptoJS.AES.encrypt(data.data.data.perfil, "g3st10n3s33n").toString();
          localStorage.cliente=this.$CryptoJS.AES.encrypt(data.data.data.cliente, "g3st10n3s33n").toString();
          this.$router.push('/Telemetria');
          }
        })
        .catch(error => {
          this.alerttext = "Validar información"
          this.dialog=true
        })
        }
        else
        {
          this.alerttext="campo vacio";
          this.dialog=true;
        }
        }
        if (this.funcion==1)
        {
        if (this.email.length!=0 && this.password.length!=0 && this.password1.length!=0 )
        {
          if (this.password==this.password1)
          {
            let json={"name":this.nombre,"email":this.email,"password":this.password};
            axios.post("https://api.coenergia.app/api/register",json)
            .then(data=>{this.dialog=false,this.alerttext = "Registrado correctamente",
              this.dialog=true,this.email='',this.password='',this.password1='',this.funcion=0,this.mp=true,this.mp1=false,this.nm=false,this.$refs.btn0.innerHTML="Login",this.$refs.btn1.innerHTML="Registrarse"
            })
            .catch(error=>{
              this.alerttext = "Error al registra usuario"
              this.dialog=true
            }
              )
          }
          else
          {
          this.alerttext="las contraseñas no coinciden";
          this.dialog=true;            
          }
        }
        else
        {
          this.alerttext="campo vacio";
          this.dialog=true;
        }
        }
        if (this.funcion==2)
        {
        if (this.email.length!=0)
        {
            let json={
              "email":this.email,
            };
            axios.post("https://api.coenergia.app/api/recovery",json)
            .then(data=>{
              this.alerttext = "Por favor revise su correo"
              this.dialog=true,this.email='',this.password='',this.password1='',this.funcion=0,this.mp=true,this.mp1=false,this.nm=false,
              this.$refs.btn0.innerHTML="Login"
              this.$refs.btn1.innerHTML="Registrarse"
              this.$refs.btn2.innerHTML="Reset Password"
            })
            .catch(error=>{
              this.alerttext = "Error al registra usuario"
              this.dialog=true
            }
              )
        }
        else
        {
          this.alerttext="campo vacio";
          this.dialog=true;
        }
        }        
      },
      Reset()
      {
        if(this.$refs.btn2.innerHTML=="Reset Password")
        {
        this.$refs.btn0.innerHTML="Solicitar"
        this.$refs.btn2.innerHTML="Login"
        this.email='',this.password='',this.password1='',this.funcion=2,this.mp=false,this.mp1=false,this.nm=false
        }
        else
        {
        this.$refs.btn0.innerHTML="Ingresar"
        this.$refs.btn2.innerHTML="Reset Password"
        this.email='',this.password='',this.password1='',this.funcion=0,this.mp=true,this.mp1=false,this.nm=false
        }
      },
      registrarse()
      {
        if(this.$refs.btn1.innerHTML=="Registrarse")
        {
        this.$refs.btn0.innerHTML="Crear"
        this.$refs.btn1.innerHTML="Login"
        this.nombre='',this.email='',this.password='',this.password1='',this.funcion=1,this.mp=true,this.mp1=true,this.nm=true
        }
        else
        {
        this.$refs.btn0.innerHTML="Ingresar"
        this.$refs.btn1.innerHTML="Registrarse"
        this.email='',this.password='',this.password1='',this.funcion=0,this.mp=true,this.mp1=false,this.nm=false
        }
      },
    },
  }
</script>
