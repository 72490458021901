import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCryptojs from 'vue-cryptojs'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Telemetria from '../views/Telemetria.vue'
import Mercado from '../views/Mercado.vue'
import Clientes from '../views/Clientes.vue'
import Contratos from '../views/Contratos.vue'
import Costos from '../views/Costos.vue'
import Tarifas from '../views/Tarifas.vue'
import Ahorros from '../views/Ahorros.vue'
import Facturacion from '../views/Facturacion.vue'
import Suministro from '../views/Suministro.vue'
import Parametros from '../views/Parametros.vue'
import Coenergia from '../views/Coenergia.vue'
import Referencia from '../views/Referencia.vue'
import Facturas from '../views/Facturas.vue'
import Informacion from '../views/Informacion.vue'
import Equipos from '../views/Equipos.vue'
import Formato from '../views/Formato.vue'
import Informes from '../views/Informes.vue'
import Auditoria from '../views/Auditoria.vue'
import ConsultaTarifas from '../views/ConsultaTarifas.vue'

Vue.use(VueRouter)
Vue.use(VueCryptojs)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },  
  {
    path: '/Menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/Telemetria',
    name: 'Telemetria',
    component: Telemetria
  },
  {
    path: '/Contratos',
    name: 'Contratos',
    component: Contratos
  },  
  {
    path: '/Mercado',
    name: 'Mercado',
    component: Mercado
  },
  {
    path: '/Clientes',
    name: 'Clientes',
    component: Clientes
  },
  {
    path: '/Tarifas',
    name: 'Tarifas',
    component: Tarifas
  },
  {
    path: '/Costos',
    name: 'Costos',
    component: Costos
  },
  {
    path: '/Ahorros',
    name: 'Ahorros',
    component: Ahorros
  },  
  {
    path: '/Facturacion',
    name: 'Facturacion',
    component: Facturacion
  },
  {
    path: '/Suministro',
    name: 'Suministro',
    component: Suministro
  },
  {
    path: '/Parametros',
    name: 'Parametros',
    component: Parametros
  },
  {
    path: '/Coenergia',
    name: 'Coenergia',
    component: Coenergia
  },
  {
    path: '/Referencia',
    name: 'Referencia',
    component: Referencia
  },
  {
    path: '/Facturas',
    name: 'Facturas',
    component: Facturas
  }, 
  {
    path: '/Informacion',
    name: 'Informacion',
    component: Informacion
  },    
  {
    path: '/Equipos',
    name: 'Equipos',
    component: Equipos
  }, 
  {
    path: '/Formato',
    name: 'Formato',
    component: Formato
  }, 
  {
    path: '/Informes',
    name: 'Informes',
    component: Informes
  },   
  {
    path: '/Auditoria',
    name: 'Auditoria',
    component: Auditoria
  },   
  {
    path: '/ConsultaTarifas',
    name: 'ConsultaTarifas',
    component: ConsultaTarifas
  },   

]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const name = localStorage.getItem('token');
  if(name){
    next();
  }else{
    if (to.name != 'Home' && to.name != 'Login') {
      next('/');
    }else
    {
      next();
    } 
  }
});
export default router
