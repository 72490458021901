<template>
    <div>
        <Header @refrescar="refrescar"/><br>
        <v-row class="d-none d-sm-flex mt-5">
            <template>
              <v-row dense>
                <v-row v-if="i>0" dense v-for="(item, i) in headers" class="pt-1 pb-3 mt-1"><br><br>
                  <v-card color="white" class="mx-auto" width="350" elevation=1>
                    <v-container fluid width="1000">
                      <v-row><v-col style="text-align:left;width:300" class="text-h7 blue--text">{{item.text}} {{gano}}</v-col></v-row><v-divider></v-divider>
                        <v-row class='pt-5' dense><v-col style="text-align:center;width: 100%;color:black;" class="text-h5">${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(facturas[i-1].valor))}}</v-col></v-row>
                    </v-container>   
                  </v-card><br><br>
                </v-row> 
              </v-row> 
            </template>
        </v-row>
        <br><v-row>            
          <v-simple-table v-if="resumen==true" style="width:100%"  ref="tabla" :fixed-header="true" :height="this.altura">
          <template v-slot:default>
            <thead>
               <tr>
                <th v-if="item.text.length==3" class="text-left blue--text font-weight-large" v-for="item in headers1">
                 {{item.text}}
                </th>
                <th v-if="item.text.length>3" class="text-right" v-for="item in headers1">
                 {{item.text}}
                </th>
              </tr>           
            </thead>
            <tbody>
             <tr v-for="(item,i) in contenido1">
                <td v-if="item.Mes=='Total'" class="text-left font-weight-bold" >
                  {{item.Mes}}
                </td>
                <td v-if="item.Mes!='Total'" >
                  {{item.Mes}}
                </td>                
                <td v-if="item.Mes!='Total'" class="text-right" v-for="(contador,j) in fechas">
                   ${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(String(item[j]).replace(/,/g,".")))}}
                </td>
                <td v-if="item.Mes=='Total'" class="text-right font-weight-bold" v-for="(contador,j) in fechas">
                   ${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(String(item[j]).replace(/,/g,".")))}}
                </td>
               </tr>
            </tbody>
          </template>
        </v-simple-table>        
          </v-row>
          <v-container class="mt-8 d-flex d-sm-none">
            <template>
              <v-row dense>
                <v-row v-if="i>0" dense v-for="(item, i) in headers" class="pt-1 pb-3 mt-1"><br><br>
                  <v-card color="white" class="mx-auto" width="350" elevation=1>
                    <v-container fluid width="1000">
                      <v-row><v-col style="text-align:left;width:300" class="text-h7 blue--text">{{item.text}} {{gano}}</v-col></v-row><v-divider></v-divider>
                        <v-row class='pt-5' dense><v-col style="text-align:center;width: 100%;color:black;" class="text-h5">${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(facturas[i-1].valor))}}</v-col></v-row>
                    </v-container>   
                  </v-card><br><br>
                </v-row> 
              </v-row> 
            </template>
          </v-container>
        <div class="text-center">
            <v-dialog width="auto" v-model="year" >
              <div>
                <Datepicker v-model="date" :inline="true" :language="es" minimum-view="year" @input="saveyear()"></Datepicker>
              </div>
            </v-dialog>
          </div><br><br>
              <v-footer color="#1b3af4" fixed padless><v-row justify="center" no-gutters><v-btn class="text-body-2" v-for="link in links" :key="link" color="white" text @click="cambiarfecha(link)">{{ link }}</v-btn></v-row></v-footer>
    </div>
</template>
<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import Header from '@/components/Header.vue';
import {en, es} from 'vuejs-datepicker/dist/locale'
  export default {
    name: 'Menu',
    components:{
        Header,
        Datepicker,
    },
    data () {
        return {
        items: [],
        valores:null,es:es,date: null,gano:'',year:false,anchura:0,altura:0,orgn:1,value:0,
        links: ['Año','Resumen',],
        meses : ['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
        headers: [],headers1: [],contenido1: [],facturas:[],contenido: [],formato:[],campo:[],texto:[],estilo:[],fechas:[],resumen:false,
        fechareporte:null,matriz:[],totales:[],referencia:[],coenergia:[],comercializador:[],
        }
    },
      mounted () {
        this.formato=[]
        this.altura=screen.height-((screen.height*25)/100)
        this.getNow(0)
      },
      methods:{
      cambiarfecha(link){
        switch (link) {case 'Año':this.resumen=false; this.year = true;break;case 'Resumen':this.gano='0000';this.solicitardatos();break;default:}
      },
      refrescar(valor){
        this.getNow(0)       
      },
      saveyear(){this.year = false;this.mesesl="12";this.getNow(1)},
      getNow(flag)
      {
        let today='';
        if (flag==0){today=new Date();}else{today=this.date;}
        if (flag==0){this.gano=String(today.getFullYear());}else{this.gano=String(today.getFullYear());}
        this.solicitardatos();
      },
      solicitardatos()
      {
        this.items= [];
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
            "fecha": this.gano,
        };
        axios.post("https://api.coenergia.app/api/atra",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
              this.facturas=data.data
              this.llenartabla(this.value)
        })
        .catch(this.erro_msg = "Validar información",
          )
      },

      llenartabla(){
        this.headers=[];this.contenido=[];
        if (this.gano!='0000')
        {
        var datos={}
        datos['text']=this.gano;
        datos['value']='Parametro'
        datos['sortable']=false
        datos['align']='center'
        this.headers.push(datos)
        for (var i =0; i < this.facturas.length; i++)
          {
          var datos={}
          datos['text']=this.meses[parseInt(this.facturas[i].fechainicio.substring(5,7))-1];
          datos['value']=i.toString()
          datos['sortable']=false
          datos['align']='center'
          this.headers.push(datos)
          }
        for (var j =0; j < this.facturas.length; j++)
        {
          this.facturas[j].valor=(parseFloat(this.facturas[j].tfseop)-parseFloat(this.facturas[j].tfsece)).toFixed(0)
        }  
        }
        else
        {
        for (var j =0; j < this.facturas.length; j++)
        {
          this.facturas[j].valor=(parseFloat(this.facturas[j].tfseop)-parseFloat(this.facturas[j].tfsece)).toFixed(0)
        }  
          this.resumen=true
          this.headers1=[]
          this.contenido1=[]
          this.fechas=[]
          this.matriz=[]
          this.totales=[]
          for (var i =0; i < this.facturas.length; i++)
          {
            var yeartitulo=this.facturas[i].fechainicio.substring(0,4)
            if(this.fechas.indexOf(yeartitulo)==-1)
            {
              this.fechas.push(yeartitulo)
            }
          }
          var datos={}
          datos['text']='Mes';
          datos['value']='Mes'
          datos['sortable']=false
          datos['align']='center'
          this.headers1.push(datos)
          for (var i =0; i < this.fechas.length; i++)
          {
            var datos={}
            for (var j =0; j < 12; j++)
            {
            datos[j]=0;
            }
            this.matriz.push(datos)
          }
          for (var i =0; i < this.facturas.length; i++)
          {  
            var fec=this.facturas[i].fechainicio.substring(0,4)
            var pos=this.fechas.indexOf(fec.toString())
            var mon=parseInt(this.facturas[i].fechainicio.substring(5,7))-1
            var info=parseFloat(this.facturas[i].valor)
            var value=this.matriz[pos][mon]
            var suma=info+value
            this.matriz[pos][mon]=suma
          }
          for (var i =0; i < this.fechas.length; i++)
          {
          var datos={}
          datos['text']=this.fechas[i].toString();
          datos['value']=i.toString()
          datos['sortable']=false
          datos['align']='center'
          this.headers1.push(datos)
          }
          for (var i =0; i < 12; i++)
          {
          var datos={}
          datos['Mes']=this.meses[i];
          for (var j =0; j < this.fechas.length; j++)
          {
            datos[j.toString()]=this.matriz[j][i]
          }
          this.contenido1.push(datos)
          }
          for (var i =0; i < this.matriz.length; i++)
          {
            var total=0
            for (var j =0; j <12; j++)
            {
            total=total+parseFloat(this.matriz[i][j])
            }
            this.totales.push(total)
          }
          var datos={}
          datos['Mes']='Total';
          for (var i =0; i < this.matriz.length; i++)
          {
          datos[i.toString()]=this.totales[i];  
          }
          this.contenido1.push(datos)
          //this.$refs.tabla.$forceUpdate()
        }
      },
            calculos()
      {
        console.log(this.facturas)
        for (var j =0; j < this.facturas.length; j++)
        {
          this.facturas[j].valor=this.facturas[j].tfsecm
        }
      },
      },     
  }
</script>
<style>
table.v-table thead tr th {
  font-size: 10px!important;
}
table.dataTable.dataTable_width_auto {
  width: auto;
}
</style>