<template>
  <div>
   <v-row align="center" justify="center" class="hero">
       <v-col cols="12" xl="2" lg="2" />
       <v-col cols="12" xl="8" lg="8">
         <v-container>
            <p class="font-2  font-title white--text">
                <v-btn @click="ingresar" class="bg" outlined color="white" x-large>Ingresar</v-btn>
            </p>
            <p class="font-2  font-title white--text">Energía que se ve</p>
          <p class="font-4 font-desc  white--text">Administra y gestiona tu consumo energético</p>
         </v-container>
       </v-col>
       <v-col cols="12" xl="2" lg="2" />
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'Hero',
    components: {
    },
          methods: {
    ingresar: function (event,next) {
      if (this.$route.path == '/') {
        this.$router.push({ path: 'Login' });
      } 
    }
  },
  }
</script>
<style>
.hero{
  height :140vh;
  background: url('../assets/hero.jpg');
}
.font-title{
  font-weight: 600;
  text-align: center;
}
.font-desc{
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width:800px) {
  .hero{
  height :120vh;
  background: url('../assets/hero.jpg');
  background-size: cover;
}
    .font-title{
      text-align: left;
      line-height: 1.5;
    }
    .font-desc{
        font-weight: 500;
        text-align: left;
    }
}
</style>

