<template>
    <div>
        <Header />
          <v-container fluid>
            <v-row class="pt-0 pb-0 ">
                <v-col cols="12" sm="6" md="6" class="d-none d-sm-flex">
                  <v-container>
                    <template>
                      <v-simple-table class="mt-4" fixed-header  height="500px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Campos disponibles
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in items"
                              :key="item.campo"
                            >
                              <td @click="handleClick(item.campo,item.identificacion)">{{ item.campo }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-container>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-container>
                    <template>
                      <v-simple-table ref="tabla" class="mt-4" fixed-header  height="500px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Plantilla factura
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in campos">
                              <td @click="seleccionar(i)" :style="item.estilo">{{ item.nombre }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                    <template>
                      <v-row class="mt-10" align="center" justify="space-around">
                        <v-btn outlined color="primary" @click="funcion(0)">Espacio</v-btn>
                        <v-btn outlined color="primary" @click="funcion(1)">Borrar</v-btn>
                        <v-btn outlined color="primary" @click="funcion(2)">Subir</v-btn>
                        <v-btn outlined color="primary" @click="funcion(3)">Bajar</v-btn>
                        <v-btn outlined color="primary" @click="funcion(4)">Negrilla</v-btn>
                        <v-btn outlined color="success" @click="funcion(5)">Guardar</v-btn>
                      </v-row>
                    </template>
                  </v-container>
                </v-col>
            </v-row>
          </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Formato',
    components:{
        Header
    },
        data () {
        return {
        items: null,
        filas: null,
        campos: [],
        fondo:0,
        fila:0,
        selectedRow: null,
        }
    },
    mounted () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "funcion": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
        };
        axios.post("https://api.coenergia.app/api/listarcampos",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
          this.items=data.data.data;
          console.log(data);  
          let json= {
              "funcion": '1',
          };
          axios.post("https://api.coenergia.app/api/listarformato",json,{
          headers: {
                  'Authorization': `Bearer ${token}`
          }})
          .then(data=>{
            this.campos=data.data.data;
          })
          .catch(this.erro_msg = "Validar información",
            ) 
        })
        .catch(this.erro_msg = "Validar información",
          )
      },
      methods:{
      handleClick(v1,v2) {
        var datos=[]
        datos['nombre']=v1
        datos['identificacion']=v2
        datos['estilo']="font-weight:normal"
        this.campos.push(datos)
      },
      negrilla(value) {

      }, 
      seleccionar(value)
      {
        this.fila=value
      },     
      funcion(opcion) {
        switch (opcion) {
            case 0:var datos=[];datos['nombre']='';datos['identificacion']='';this.campos.push(datos)
            break;
            case 1:this.campos.splice(this.fila,1)
            break;
            case 2:
            if (this.fila>0)
            {
            var actual=[]
            actual['nombre']=this.campos[this.fila].nombre
            actual['identificacion']=this.campos[this.fila].identificacion
            actual['estilo']=this.campos[this.fila].estilo
            var anterior=[]
            anterior['nombre']=this.campos[this.fila-1].nombre
            anterior['identificacion']=this.campos[this.fila-1].identificacion
            anterior['estilo']=this.campos[this.fila-1].estilo
            this.campos[this.fila].nombre=anterior['nombre']
            this.campos[this.fila].identificacion=anterior['identificacion']
            this.campos[this.fila].estilo=anterior['estilo']
            this.campos[this.fila-1].nombre=actual['nombre']
            this.campos[this.fila-1].identificacion=actual['identificacion']
            this.campos[this.fila-1].estilo=actual['estilo']
            this.fila=this.fila-1
            }
            break;
            case 3:
            if (this.fila<this.campos.length)
            {
            var actual=[]
            actual['nombre']=this.campos[this.fila].nombre
            actual['identificacion']=this.campos[this.fila].identificacion
            actual['estilo']=this.campos[this.fila].estilo
            var siguiente=[]
            siguiente['nombre']=this.campos[this.fila+1].nombre
            siguiente['identificacion']=this.campos[this.fila+1].identificacion
            siguiente['estilo']=this.campos[this.fila+1].estilo
            this.campos[this.fila].nombre=siguiente['nombre']
            this.campos[this.fila].identificacion=siguiente['identificacion']
            this.campos[this.fila].estilo=siguiente['estilo']
            this.campos[this.fila+1].nombre=actual['nombre']
            this.campos[this.fila+1].identificacion=actual['identificacion']
            this.campos[this.fila+1].estilo=actual['estilo']
            this.fila=this.fila+1
            }            
            break;
            case 4:        
            if (this.campos[this.fila].estilo=="font-weight:normal")
              {
                this.campos[this.fila].estilo="font-weight:bold"
              }
              else
              {
                this.campos[this.fila].estilo="font-weight:normal"
              }
            break;
            case 5:
            console.log(this.campos)
            let json= {
            "campos":this.convertirjson(this.campos),
            };
            let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
            axios.post("https://api.coenergia.app/api/salvarformato",json,{
            headers: {
                'Authorization': `Bearer ${token}`
            }})
            .then(data=>{
              console.log(data)
              })
              .catch(this.erro_msg = "Validar información",
              )
            break;                                                             
            default:
        }
        this.$refs.tabla.$forceUpdate()
      },
      convertirjson(matriz)
      {
        var cadena=''
        for (let j = 0; j < matriz.length; j++)
        {
          cadena=cadena+matriz[j].identificacion+","+matriz[j].nombre+","+matriz[j].estilo+"|"
        }
        return cadena.slice(0,-1)
      }
      },
  }


</script>

<style>
.custom-highlight-row{
  background-color:#DADADC
}
</style"