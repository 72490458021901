<template>
  <div>
    <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="headers" :items="informacion" sort-by="Nombre" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Equipos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }"><v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Nuevo</v-btn></template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.frontera" label="Frontera"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.equipo" label="Equipo"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.modelo" label="Modelo"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.serial" label="Serial"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.ip" label="Ip"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.fpt" label="Fpt"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.tcs" label="Tcs"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.tps" label="Tps"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.estado" label="Estado"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.activo" label="Activo"></v-text-field></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea borrar este equipo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Clientes',
    components:{
        Header
    },
     data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'Frontera',align: 'start',sortable: false,value: 'frontera',},
        { text: 'Equipo', value: 'equipo' },
        { text: 'Modelo', value: 'modelo' },
        { text: 'Serial', value: 'serial' },
        { text: 'Ip', value: 'ip', sortable: false },
        { text: 'Fpt', value: 'fpt', sortable: false },
        { text: 'Tcs', value: 'tcs', sortable: false },
        { text: 'Tps', value: 'tps', sortable: false },
        { text: 'Estado', value: 'estado', sortable: false },
        { text: 'Activo', value: 'activo', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },        
      ],
      informacion: [],editedIndex: -1,
      editedItem: {pais:'Colombia'},
      defaultItem: {},
      select: { departamento: 'Ciudad'},
      itemsd: [],itemsc: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo equipo' : 'Editar Equipo'
      },
    },
    mounted () {
      this.Consultar()
  },

    methods: {
      Consultar(){
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let frontera=this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json={"frontera":frontera};
        axios.post("https://api.coenergia.app/api/lequipos",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      },
      refrescar(valor){
        this.tamano=300-(screen.width/11)
        this.Consultar();
      },
      editItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "id": this.editedItem.id
        };
        axios.post("https://api.coenergia.app/api/bequipos",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            //this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validarvacio())
        {
        console.log(this.editedItem)
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        var funcion='sequipos';
        if(this.formTitle=='Editar Equipo'){funcion='eequipos';}
        axios.post("https://api.coenergia.app/api/"+funcion,this.editedItem,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.close()
        }
        else{alert('faltan datos')}
      },
      validarvacio () {
        var estado=true;
        if(this.editedItem.frontera==null){estado=false;}
        if(this.editedItem.equipo==null){estado=false;}
        if(this.editedItem.modelo==null){estado=false;}
        if(this.editedItem.serial==null){estado=false;}
        if(this.editedItem.ip==null){estado=false;}
        if(this.editedItem.fpt==null){estado=false;}
        if(this.editedItem.tcs==null){estado=false;}
        if(this.editedItem.tps==null){estado=false;}
        if(this.editedItem.estado==null){estado=false;}
        if(this.editedItem.activo==null){estado=false;}
        return estado;
      },
  }}


</script>