<template>
  <div>
    <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="dinamicos" :items="listar" :fixed-header="true" :disable-pagination="true" :hide-default-footer="true" :height="450" :calculate-widths="true" :width="1000" sort-by="Nombre" class="elevation-0">
    <template v-slot:top>
      <v-toolbar >
        <v-toolbar-title>Facturas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="1200px">
          <template v-slot:activator="{ on, attrs }"><v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Nuevo</v-btn></template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="item in dinamicos" cols="12" sm="6" md="3"><v-text-field :label="item.text" v-model="editedItem[item.value]"></v-text-field></v-col>
                 </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea borrar este cliente?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Facturas',
    components:{
        Header
    },
     data: () => ({
      editedItem: {},
      modali: false,modalf: false,modale: false,modalv: false,modalp: false,dialog: false,file:null,
      dialogDelete: false,
      iniciales:['Frontera','Fecha inicio','Fecha vencimiento','Fecha expedicion','Fecha pago','Numero factura','valor','Factura pdf'],
      ebase:[],dbase:[],campos: [],dinamicos: [],dinamicos1: [],informacion: [],editedIndex: -1,defaultItem: {},itemso: [],itemsc: [],itemsf: [],listar:[],
    }),
        mounted () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "funcion": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
        };
        axios.post("https://api.coenergia.app/api/listarformato",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
          var listado=data.data.data;
          for (var i =0; i < listado.length; i++)
                {
                  var datos={}
                  datos['text']=listado[i].nombre.replace(/ /g,"_");
                  datos['value']=listado[i].identificacion
                  datos['sortable']=false
                  datos['align']='center'
                  if (datos['text'].length>0 && datos['value']!='0' )
                    {
                    this.dinamicos.push(datos)
                    }
                  }
          var datos={};datos['text']='Numero_factura';datos['value']='numerofactura';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Valor';datos['value']='valor';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Fecha_pago_factura';datos['value']='fechapago';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Fecha_Vencimiento';datos['value']='fechavencimiento';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Fecha_expedicion';datos['value']='fechaexpedicion';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Fecha_finalizacion';datos['value']='fechafin';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Fecha_inicio';datos['value']='fechainicio';datos['sortable']=false;datos['align']='center';this.dinamicos.unshift(datos)
          var datos={};datos['text']='Frontera';datos['value']='frontera';datos['sortable']=false;datos['align']='start';datos['align']='center';this.dinamicos.unshift(datos)
        })
        .catch(this.erro_msg = "Validar información",
          )
        this.solicitardatos()
      },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva factura' : 'Editar factura'
      },
    },

     methods: {
    solicitardatos()
    {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
        };
        axios.post("https://api.coenergia.app/api/lfacturas",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
            this.listar=data.data;
            for (var i =0; i < this.listar.length; i++)
            {
              this.listar[i].campo1=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo1).replace(/,/g,".")));
              this.listar[i].campo2=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo2).replace(/,/g,".")));
              this.listar[i].campo3=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo3).replace(/,/g,".")));
              this.listar[i].campo4=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo4).replace(/,/g,".")));
              this.listar[i].campo5=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo5).replace(/,/g,".")));
              this.listar[i].campo6=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo6).replace(/,/g,".")));
              this.listar[i].campo7=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo7).replace(/,/g,".")));
              this.listar[i].campo8=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo8).replace(/,/g,".")));
              this.listar[i].campo9=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo9).replace(/,/g,".")));
              this.listar[i].campo10=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo10).replace(/,/g,".")));
              this.listar[i].campo11=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo11).replace(/,/g,".")));
              this.listar[i].campo12=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo12).replace(/,/g,".")));
              this.listar[i].campo13=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo13).replace(/,/g,".")));
              this.listar[i].campo14=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo14).replace(/,/g,".")));
              this.listar[i].campo15=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo15).replace(/,/g,".")));
              this.listar[i].campo16=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo16).replace(/,/g,".")));
              this.listar[i].campo17=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo17).replace(/,/g,".")));
              this.listar[i].campo18=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo18).replace(/,/g,".")));
              this.listar[i].campo19=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo19).replace(/,/g,".")));
              this.listar[i].campo20=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo20).replace(/,/g,".")));
              this.listar[i].campo21=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo21).replace(/,/g,".")));
              this.listar[i].campo22=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo22).replace(/,/g,".")));
              this.listar[i].campo23=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo23).replace(/,/g,".")));
              this.listar[i].campo24=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo24).replace(/,/g,".")));
              this.listar[i].campo25=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo25).replace(/,/g,".")));
              this.listar[i].campo26=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo26).replace(/,/g,".")));
              this.listar[i].campo27=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo27).replace(/,/g,".")));
              this.listar[i].campo28=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo28).replace(/,/g,".")));
              this.listar[i].campo29=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo29).replace(/,/g,".")));
              this.listar[i].campo30=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo30).replace(/,/g,".")));
              this.listar[i].campo31=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo31).replace(/,/g,".")));
              this.listar[i].campo32=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo32).replace(/,/g,".")));
              this.listar[i].campo33=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo33).replace(/,/g,".")));
              this.listar[i].campo34=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo34).replace(/,/g,".")));
              this.listar[i].campo35=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo35).replace(/,/g,".")));
              this.listar[i].campo36=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo36).replace(/,/g,".")));
              this.listar[i].campo37=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo37).replace(/,/g,".")));
              this.listar[i].campo38=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo38).replace(/,/g,".")));
              this.listar[i].campo39=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo39).replace(/,/g,".")));
              this.listar[i].campo40=String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format((this.listar[i].campo40).replace(/,/g,".")));
            }
        })
        .catch(
          this.erro_msg = "Validar información",
          )
    },

      deleteItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "id": this.editedItem.id
        };
        axios.post("https://api.coenergia.app/api/bfacturas",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{

            this.solicitardatos()
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.closeDelete() 
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validarvacio())
        {
            let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
            let formData = new FormData();
            var funcion='sfacturas';
            if(this.formTitle=='Editar factura'){funcion='efacturas';}
            this.dbase=['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'];
            this.ebase=['campo0','campo1','campo2','campo3','campo4','campo5','campo6','campo7','campo8','campo9','campo10','campo11','campo12','campo13','campo14','campo15','campo16','campo17','campo18','campo19','campo20','campo21','campo22','campo23','campo24','campo25','campo26','campo27','campo28','campo29','campo30','campo31','campo32','campo33','campo34','campo35','campo36','campo37','campo38','campo39','campo40'];
            for (var i =0; i < 40; i++)
                {
                  if(typeof (Object.keys(this.editedItem)[i])!='undefined')
                  {
                    if(!isNaN(Object.keys(this.editedItem)[i].replace('campo','')))
                    {
                      this.dbase[Object.keys(this.editedItem)[i].replace('campo','')]=Object.values(this.editedItem)[i]
                    }
                  }
                }
            for (var i =0; i < 41; i++)
                {
                  formData.append(this.ebase[i],this.dbase[i]);
                }
            formData.append('archivo', this.file);
            formData.append('frontera', this.editedItem.frontera);
            formData.append('fechainicio', this.editedItem.fechainicio);
            formData.append('fechafin', this.editedItem.fechafin);
            formData.append('fechavencimiento', this.editedItem.fechavencimiento);
            formData.append('fechaexpedicion', this.editedItem.fechaexpedicion);
            formData.append('fechapago', this.editedItem.fechapago);
            formData.append('numerofactura', this.editedItem.numerofactura);
            formData.append('valor', this.editedItem.valor);
            formData.append('factura', this.editedItem.frontera+this.editedItem.fechainicio.replace('-','')+'.pdf');
          
            axios.post( 'https://api.coenergia.app/api/'+funcion,
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
              }
            ).then(data=>{this.solicitardatos()})
        .catch(function(){
          console.log('FAILURE!!');
        });
        this.close()
        }
        else{alert('faltan datos')}
      },
      validarvacio () {
        var estado=true;
        if((this.dinamicos.length+1)!=Object.keys(this.editedItem).length)
        {estado=false}
        return estado;
      },
    salvarfecha (fecha) 
    {
        editedItem.inicio = false
    },
    handleFileUpload(){
        this.file = this.$refs.file.files[0];
        this.editedItem.file="ok"
      }, 
      refrescar(valor){
        this.solicitardatos()
      },          

  }}


</script>