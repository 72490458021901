<template>
  <div class="contact-container">
      <v-row align="center" justify="center" >
          <v-col cols="12" xl="6" lg="6" class="" style="position:relative">
              <p class="font-3 weight-600 d-flex d-sm-none text-center ">Caracteristicas de eseen</p>
             
              <v-img eager src="../assets/contact.png"></v-img>
              <div style="" class="box box-2">
                   <p class="mb-n1 font-4 blue--text weight-600 text-center">Telemedida</p>
              </div>
               <div style="" class="box box-3">
                   <p class="mb-n1 font-3 blue--text weight-600 text-center">Ahorros</p>
              </div>
          </v-col>
           <v-col cols="12" xl="6" lg="6" class="">
              <p class="font-3 weight-600 d-none d-sm-flex">Caracteristicas de eseen</p>
              <p class="font-4 font-weight-light">essen te permite ver y analizar el mercado en todos los aspectos, revisar tus consumos en tiempo real con el fin que puedas aplicar planes de accion para reducir consumos</p>
              <br>
          </v-col>
      </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.contact-container{
    padding: 4%;
}
.box{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0);
    height:13vh;
    min-width:100px;
    width: 150px;
    border-radius: 25px !important;
    position:absolute;
    background: white;
    padding-top: 2%;
    
}
.box-1{
    top:15%;
    right: -5%;
}
.box-2{
    top:85%;
    right: -5%;
}
.box-3{
    top:75%;
    left: -5%;
}
@media screen and (max-width:600px) {
    .contact-container{
        padding: 10%;
    }
    .box{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height:7vh;
    min-width:100px;
    width: 100px;
    border-radius: 20px !important;
    position:absolute;
    background: white;
    padding-top: 2.5%;
    
}
    .box-1{
    top:35%;
    right: -5%;
}
.box-2{
    top:80%;
    right: -5%;
}
.box-3{
    top:75%;
    left: -5%;
}
}
</style>