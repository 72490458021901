<template>
    <div>
        <Header @refrescar="refrescar"/>
        <v-container class="mt-8">
            <template>
                <v-row dense>
                    <v-row flex dense v-for="(item, i) in items" class="pt-1 pb-2 mt-1"><br><br>
                        <v-card class="ml-0 mr-0" @click="ejecutar(item.direccion)" :color="item.colortarjeta" width="360">
                            <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h7 mb-1" v-text="item.titulo"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.descripcion"></v-list-item-subtitle>
                            </v-list-item-content><v-icon x-large :color="item.coloricono">{{item.icono}}</v-icon></v-list-item>
                        </v-card><br><br>
                    </v-row> 
                 </v-row> 
            </template>
        </v-container>
        <v-dialog v-model="dialog" max-width="290"><v-card><br><v-card-text>{{this.alerttext}}</v-card-text><v-card-actions><v-spacer></v-spacer><v-btn color="green darken-1" text @click="dialog = false">Ok</v-btn></v-card-actions></v-card></v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Menu',
    components:{
        Header
    },
    data () {
        return {
        items: null,
        dialog:false,
        alerttext:'',
        }
    },
      mounted () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let perfil=this.$CryptoJS.AES.decrypt(localStorage.perfil,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "perfil":perfil
        };
        axios.post("https://api.coenergia.app/api/menu",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
            this.items=data.data;
            if (this.items.length==0)
            {
                this.alerttext="Pendiente por asignar perfil, comuniquese con el administrador"
                this.dialog=true
            }
        })
        .catch(
          this.erro_msg = "Validar información",
          )
  },
      methods:{
      ejecutar(dato){
          this.$router.push(dato);
      },
      refrescar(valor)
      {

      },
    }
  }
</script>