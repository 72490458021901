<template>
    <div v-touch="{left: () => swipe('Left'),right: () => swipe('Right')}">
        <Header @refrescar="refrescar"/>
          <v-container fluid>
            <v-row class="pt-0 pb-0 ">
                <v-col cols="12" sm="4" md="4">
                  <div class="pt-8" style="position: sticky; top: 16px;border:none">
                      <v-row dense style="font-size:100%;background:#E6E7EC;color: black;border:none;">
                        <v-col style="text-align: center;width: 14%" ref="f0" @click="funcion(0)">$</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f1" @click="funcion(1)">kW</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f2" @click="funcion(2)">I</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f3" @click="funcion(3)">V</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f4" @click="funcion(4)">FP</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f5" @click="funcion(5)">Hz</v-col>
                        <v-col style="text-align: center;width: 14%" ref="f6" @click="funcion(6)">°C</v-col>
                      </v-row>
                    <v-container  elevation="1" class="mt-2 pl-2 mb-0 pt-3 pb-0" style="border:none;border-color:gray;border-radius: 2% 2% 2% 2%;"  max-width="100%">
                      <v-row>
                            <v-toolbar-title class="text-h6 ml-3">{{this.titulo[this.posicion]}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="text-h5 black--text text-bold mr-5">{{this.valortotal}}</v-toolbar-title>
                      </v-row><v-row no-gutters class="mt-1 pb-2" @click="modificarfecha()">{{this.display}}</v-row>Tarifa:{{this.tariactual}}
                    </v-container>
                    <v-divider horizontal></v-divider>
                    <v-container v-if="this.switch===false" elevation="1"  max-width="100">
                        <v-row style="border:none;border-color:gray;border-radius: 2% 2% 2% 2%;">
                          <v-col class="text-subtitle-1 pr-3" cols="4">{{this.rmaximo}}<v-row class="text-h6 text-bold ml-0 mt-0">{{this.valormaximo}}</v-row></v-col>
                          <v-col cols="2"><v-avatar color="#ff0000" size="35"><span class="white--text text-body-1">{{this.dmaximo}}</span></v-avatar></v-col>
                          <v-divider vertical></v-divider>
                          <v-col class="text-subtitle-1" cols="4">{{this.rminimo}}<v-row class="text-h6 text-bold ml-0 mt-0">{{this.valorminimo}}</v-row></v-col>
                          <v-col cols="2"><v-avatar color="#008000" size="35"><span class="white--text text-body-1">{{this.dminimo}}</span></v-avatar></v-col>
                        </v-row>
                    </v-container>
                     <v-container fluid v-if="this.switch===true" class="mx-auto"  max-width="900">
                                <v-row dense><v-col style="text-align:left;width: 25%">Detalle</v-col><v-col style="text-align:center;width: 25%">Máximo</v-col><v-col style="text-align:center;width: 25%;">Promedio</v-col><v-col style="text-align:center;width: 25%;">Mínimo</v-col></v-row>
                                <v-row dense><v-col style="text-align:left;width: 25%;color:rgba(0,0,255, 1);">{{this.t1}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mx1}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.pr1}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mn1}}</v-col></v-row>
                                <v-row dense><v-col style="text-align:left;width: 25%;color:rgba(02,71,171, 1);">{{this.t2}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mx2}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.pr2}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mn2}}</v-col></v-row>
                                <v-row dense><v-col style="text-align:left;width: 25%;color:rgba(18,10,143, 1);">{{this.t3}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mx3}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.pr3}}</v-col><v-col style="text-align:center;width: 25%;color:black;">{{this.mn3}}</v-col></v-row> 
                      </v-container>   
                  </div>
              </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-container dense  id="scroll-target" style="max-height: 550px">
                  <v-row>
                    <div v-if="this.pc==1" class="mt-8">.</div>
                  <canvas v-show="this.vpr" id="principal" :height="this.tamano" width="auto"></canvas>
                                       <v-container v-if="this.detalle===true"  max-width="900">
                                <v-row dense><v-col style="text-align:left;width: 5%"><v-icon color="#0E5BD7" large @click="potencias(0)">mdi-arrow-left-bold</v-icon></v-col><v-col float class="text-body-2 mt-2" style="text-align:center" >{{this.energia}}</v-col><v-col style="text-align:right;width:5%;"><v-icon color="#0E5BD7" large @click="potencias(1)">mdi-arrow-right-bold</v-icon></v-col></v-row>
                      </v-container> 
                  </v-row>
                  </v-container>

                </v-col>
            </v-row>
          </v-container>
          <div class="text-center">
            <v-dialog width="auto" v-model="year" >
              <div>
                <Datepicker v-model="date" :inline="true" :language="es" minimum-view="year" @input="saveyear()"></Datepicker>
              </div>
            </v-dialog>
            <v-dialog width="auto" v-model="mes" >
              <div>
                <Datepicker v-model="date" :inline="true" :language="es" minimum-view="month" @input="savemes()"></Datepicker>
              </div>
            </v-dialog>
            <v-dialog width="auto" v-model="dia" >
              <div>
                <Datepicker v-model="date" :inline="true" :language="es" minimum-view="date" @input="savedia()"></Datepicker>
              </div>
            </v-dialog>
            <v-dialog width="auto" v-model="hora" >
              <div>
                <v-time-picker v-model="time" format="24hr" ref="picker" @click:hour="savehora(time)" class="custom-time-picker"></v-time-picker>             
              </div>
            </v-dialog>  
          </div>
          <v-footer color="#1b3af4" fixed padless><v-row justify="center" no-gutters><v-btn class="text-body-2" v-for="link in links" :key="link" color="white" text @click="cambiarfecha(link)">{{ link }}</v-btn></v-row></v-footer>
    </div>
</template>
<script>
import axios from 'axios';
import Chart from 'chart.js'
import Header from '@/components/Header.vue';
import lodash from 'lodash';
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'
import {en, es} from 'vuejs-datepicker/dist/locale'
import Timeselector from 'vue-timeselector';
  export default {
    name: 'Telemetria',
    components:{
        Header,
        Datepicker,
        Timeselector
    },
    methods:{
       refrescar(valor)
            {
      this.tamano=300-(screen.width/11)
        let $ref = this.$refs['f0'];$ref.style.backgroundColor = '#0E5BD7';$ref.style.color = '#ffffff';
        this.getNow(0);
            },
      onScroll (e) {

        let valor = e.target.scrollTop
        if (valor>0 && valor<Number(this.tamano)*1){this.offsetTop='Activa importada'} 
        if (valor>Number(this.tamano)*1 && valor<Number(this.tamano)*2){this.offsetTop='Activa exportada'} 
        if (valor>Number(this.tamano)*2 && valor<Number(this.tamano)*3){this.offsetTop='Reactiva inductiva'} 
        if (valor>Number(this.tamano)*3 && valor<Number(this.tamano)*4){this.offsetTop='Inductiva penalizada'} 
        if (valor>Number(this.tamano)*4 && valor<Number(this.tamano)*5){this.offsetTop='Reactiva capacitiva'} 
      },
      modificarfecha(){
        switch (this.rf) {

          case 'Periodo':
             this.dia = true;
             break;
          case 'Día':
             this.mes = true;
             break;
          case 'Mes':
             this.year = true;
             break;
             default:
          }

      },
      cambiarfecha(link){
        switch (link) {
          case 'Hora':
            this.hora = true;
            this.rf='Minutos';
            break;
          case 'Día':
             this.dia = false;
             this.rf='Periodo';
             this.tabla='1';
             this.getNow(2);
             break;
          case 'Mes':
             this.mes = false;
             this.rf='Día';
             this.getNow(3);
             break;
          case 'Año':
             this.year = false;
             this.rf='Mes';
             this.getNow(4);
             break;
          case 'Total':
             this.tabla='4';
             this.solicitardatos();
             this.rf='Año';
             break;
             default:
          }
      },
      async swipe (direction)
      {
        if (direction=='Left')
        {
          this.posicion=this.posicion+1
          if (this.posicion>6){this.posicion=0}
        }
        if (direction=='Right')
        {
          this.posicion=this.posicion-1
          if (this.posicion<0){this.posicion=6}
        }
        this.funcion(this.posicion)
        await this.seleccionargrafica()
      },
      async seleccionargrafica()
      {
        let valores=null;
        switch (this.posicion) {
            case 0:
            this.calculartarifas();
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.vpr=true;this.vd1=true;this.vd2=true;this.vd3=true;this.vd4=true;this.vd5=true
              if(this.cpotencia==0){
              this.energia="Total";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,0,this.parametros.fpt,this.rf)
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i])*parseFloat(this.timpact))-(parseFloat(this.registro.data[i].v3)*(parseFloat(this.parametros.fpt)/1000)*parseFloat(this.texpact))+(parseFloat(this.registro.data[i].v4)*(parseFloat(this.parametros.fpt)/1000)*parseFloat(this.treacap))+(parseFloat(this.registro.data[i].v5)*(parseFloat(this.parametros.fpt)/1000)*parseFloat(this.tpenrea))
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);
              }
              if(this.cpotencia==1){
              this.energia="Activa_importada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,0,this.parametros.fpt,this.rf);
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=parseFloat(valores.data.datasets[0].data[i])*parseFloat(this.timpact)
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);
              }
              if(this.cpotencia==2){
              this.energia="Activa_exportada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,1,this.parametros.fpt,this.rf)
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=parseFloat(valores.data.datasets[0].data[i])*parseFloat(this.texpact)
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);
              }
              if(this.cpotencia==3){
              this.energia="Reactiva_inductiva";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,2,this.parametros.fpt,this.rf)
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=parseFloat(valores.data.datasets[0].data[i])*parseFloat(0)
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);
              }
              if(this.cpotencia==4){
              this.energia="Inductiva_penalizada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,4,this.parametros.fpt,this.rf)                
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=parseFloat(valores.data.datasets[0].data[i])*parseFloat(this.tpenrea)
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);              }
              if(this.cpotencia==5){
              this.energia="Reactiva_capacitiva";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,3,this.parametros.fpt,this.rf)                
              for (var i =0; i < valores.data.datasets[0].data.length; i++)
              {
                valores.data.datasets[0].data[i]=parseFloat(valores.data.datasets[0].data[i])*parseFloat(this.tpencap)
              }
                var max = Math.max.apply(null,valores.data.datasets[0].data);
                var min = Math.min.apply(null,valores.data.datasets[0].data);
                valores.vmaximo=max;
                valores.vminimo=min;
              this.gr0=new Chart(document.getElementById('principal'),valores);                }
            this.prefijo='$';this.sufijo='',this.switch=false,this.detalle=true
            break;
            case 1:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
              if(this.gpotencia==0){
              this.energia="Activa_importada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,0,this.parametros.fpt,this.rf)
              if(this.tabla==0)
              {
                for (var i =0; i < valores.data.datasets[0].data.length; i++)
                {
                  valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i]))*12
                }               
              }
              this.gr0=new Chart(document.getElementById('principal'),valores);
              this.prefijo='';this.sufijo='kWh',this.switch=false,this.detalle=true
              }
              if(this.gpotencia==1){
              this.energia="Activa_exportada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,1,this.parametros.fpt,this.rf)
              if(this.tabla==0)
              {
                for (var i =0; i < valores.data.datasets[0].data.length; i++)
                {
                  valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i]))*12
                }               
              }
              this.gr0=new Chart(document.getElementById('principal'),valores);
              this.prefijo='';this.sufijo='kWh',this.switch=false,this.detalle=true
              }
              if(this.gpotencia==2){
              this.energia="Reactiva_inductiva";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,2,this.parametros.fpt,this.rf)
              if(this.tabla==0)
              {
                for (var i =0; i < valores.data.datasets[0].data.length; i++)
                {
                  valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i]))*12
                }               
              }
              this.gr0=new Chart(document.getElementById('principal'),valores);
              this.prefijo='';this.sufijo='kVArh',this.switch=false,this.detalle=true
              }
              if(this.gpotencia==3){
              this.energia="Inductiva_penalizada";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,4,this.parametros.fpt,this.rf)
              if(this.tabla==0)
              {
                for (var i =0; i < valores.data.datasets[0].data.length; i++)
                {
                  valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i]))*12
                }               
              }
              this.gr0=new Chart(document.getElementById('principal'),valores);
              this.prefijo='';this.sufijo='kVArh',this.switch=false,this.detalle=true
              }
              if(this.gpotencia==4){
              this.energia="Reactiva_capacitiva";
              valores=await this.graficar('Total',this.posicion,this.registro,'bar','10',this.tabla,3,this.parametros.fpt,this.rf)                
              if(this.tabla==0)
              {
                for (var i =0; i < valores.data.datasets[0].data.length; i++)
                {
                  valores.data.datasets[0].data[i]=(parseFloat(valores.data.datasets[0].data[i]))*12
                }               
              }
              this.gr0=new Chart(document.getElementById('principal'),valores);
              this.prefijo='';this.sufijo='kVArh',this.switch=false,this.detalle=true
              }
            break;
            case 2:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.gr0=new Chart(document.getElementById('principal'),valores=await this.graficar1('Lineas1','Linea2','Linea3',this.posicion,this.registro,'line','10',this.tabla,11,this.parametros.tcs,this.rf));
            this.prefijo='';this.sufijo='A';this.switch=true;this.t1='Línea1';this.t2='Línea2';this.t3='Línea3',this.detalle=false;
            break;
            case 3:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.gr0=new Chart(document.getElementById('principal'),valores=await this.graficar1('Fase1','Fase2','Fase3',this.posicion,this.registro,'line','10',this.tabla,12,this.parametros.tps,this.rf));
            this.prefijo='';this.sufijo='V';this.switch=true;this.t1='Fase1';this.t2='Fase2';this.t3='Fase3',this.detalle=false;
            break;
            case 4:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.gr0=new Chart(document.getElementById('principal'),valores=await this.graficar('Factor de potencia',this.posicion,this.registro,'line','10',this.tabla,13,'0',this.rf));
            this.prefijo='';this.sufijo='';this.switch=false,this.detalle=false;
            break;                                                
            case 5:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.gr0=new Chart(document.getElementById('principal'),valores=await this.graficar('Frecuencia',this.posicion,this.registro,'line','10',this.tabla,14,'0',this.rf));
            this.prefijo='';this.sufijo='Hz';this.switch=false,this.detalle=false;
            break;                                                
            case 6:
            if (this.gr0){this.gr0.clear();this.gr0.destroy();}
            this.gr0=new Chart(document.getElementById('principal'),valores=await this.graficar('Temperatura',this.posicion,this.registro,'line','10',this.tabla,15,'0',this.rf));
            this.prefijo='';this.sufijo='°C';this.switch=false,this.detalle=false;
            break;                                                
        }
        if (this.posicion==2 || this.posicion==3)
        {
          this.mx1=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.max[0]).toFixed(2));
          this.mx2=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.max[1]).toFixed(2));
          this.mx3=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.max[2]).toFixed(2));
          this.mn1=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.min[0]).toFixed(2));
          this.mn2=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.min[1]).toFixed(2));
          this.mn3=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.min[2]).toFixed(2));
          this.pr1=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.pro[0]).toFixed(2));
          this.pr2=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.pro[1]).toFixed(2));
          this.pr3=Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format( parseFloat(valores.pro[2]).toFixed(2));
          this.valortotal=''
        }
        else
        {
        let total=0;
        let cuentaceros=0;
        this.dmaximo=valores.maximo;
        this.dminimo=valores.minimo;
        let promedio=0;
        let full=0;
        if (this.posicion==1 && this.tabla==0)
        {
        this.valormaximo=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format((parseFloat(valores.vmaximo)*12).toFixed(2)))+this.sufijo;
        this.valorminimo=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format((parseFloat(valores.vminimo)*12).toFixed(2)))+this.sufijo;
        }
        else
        {
        this.valormaximo=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(valores.vmaximo).toFixed(2)))+this.sufijo;
        this.valorminimo=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(valores.vminimo).toFixed(2)))+this.sufijo;
        }
        for(let i = 0; i <= (valores.data.datasets[0].data).length-1; i++) 
        {
        if (parseFloat(valores.data.datasets[0].data[i])==0){cuentaceros=cuentaceros+1}
        total+=parseFloat(valores.data.datasets[0].data[i]);
        }
        full=total;
        if((valores.data.datasets[0].data).length==cuentaceros)
        {
        promedio=total/((valores.data.datasets[0].data).length);
        }
        else
        {
        promedio=total/((valores.data.datasets[0].data).length)-cuentaceros;
        }
        if (this.posicion==0){this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(full).toFixed(2)))+this.sufijo}
        if (this.posicion==1)
        {
          if (this.tabla==0)
          {
          this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(promedio).toFixed(2)))+this.sufijo
          }
          else{
          this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(full).toFixed(2)))+this.sufijo
          }
        }
        if (this.posicion==4){this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(promedio).toFixed(2)))+this.sufijo}
        if (this.posicion==5){this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(promedio).toFixed(2)))+this.sufijo}
        if (this.posicion==6){this.valortotal=this.prefijo+(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(parseFloat(promedio).toFixed(2)))+this.sufijo}
        }
        this.habilitar=1
      },
      saveyear(){this.year = false;this.gano=String(this.date.getFullYear());this.tabla='3';this.getNow(4)},
      savemes(){this.mes = false;this.gmes=String(this.date.getMonth()+1);if(this.gmes.length==1){this.gmes='0'+this.gmes;}this.tabla='2';this.getNow(3)},
      savedia(){this.dia = false;this.gdia=String(this.date.getDate());if(this.gdia.length==1){this.gdia='0'+this.gdia;};this.tabla='1';this.getNow(2)},
      savehora(){this.$nextTick(() => {this.$refs.picker.selectingHour = true;});this.hora = false;this.tabla='0';this.horaconsulta=this.time.substring(0,2);this.solicitardatos()},
      allowedStep: m => m % 60 === 0,
      solicitardatos()
      {
        this.habilitar=0
        this.mostrarfecha();
        this.fecha = this.gano+'-'+this.gmes+'-'+this.gdia;
        console.log(this.fecha)
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let frontera=this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json={"identificacion":frontera,"medidor":this.tabla,"fecha":this.fecha,"tabla":"6","hra":this.horaconsulta};
        axios.post("https://api.coenergia.app/api/tele",json,{headers: {'Authorization': `Bearer ${token}`}})
        .then(data=>{
          this.parametros=data.data[0];
          console.log(this.parametros)
            let json={"identificacion":frontera,"medidor":"84257820","fecha":this.fecha,"tabla":this.tabla,"hra":this.horaconsulta};
            axios.post("https://api.coenergia.app/api/tele",json,{headers: {'Authorization': `Bearer ${token}`}})
            .then(data=>{
                  this.registro=data
                  this.seleccionargrafica()
            })
            .catch(this.erro_msg = "Validar información",)
        })
        .catch(this.erro_msg = "Validar información",)
      },
      getNow(flag)
      {
        let today='';
        if (flag==0)
        {
          today=new Date();
          this.gano=String(today.getFullYear());
          this.gmes=String(today.getMonth()+1);if(this.gmes.length==1){this.gmes='0'+this.gmes;}
          this.gdia=String(today.getDate());if(this.gdia.length==1){this.gdia='0'+this.gdia;}
          this.solicitardatos();
        }
        if (flag==2)
        {
        this.tabla='1'
        this.solicitardatos();
        }
        if (flag==3)
        {
        this.tabla='2'
        this.solicitardatos();
        }
        if (flag==4)
        {
        this.tabla='3'
        this.solicitardatos();
        }        

        },
      mostrarfecha()
      {
        switch (this.tabla) {
            case '0':this.display=this.time.substring(0,2)+' horas del '+this.gdia+' '+this.meses[Number(this.gmes)-1]+' de '+this.gano;this.rmaximo="Maximo";this.rminimo="Minimo"
            break;
            case '1':this.display=this.gdia+' '+this.meses[Number(this.gmes)-1]+' de '+this.gano
            break;
            case '2':this.display=this.meses[Number(this.gmes)-1]+' de '+this.gano 
            break;
            case '3':this.display=this.gano
            break;
            case '4':this.display='Historico total'
            break;                                                
            default:
        }
      },
      calculartarifas()
      {
        let posit;
        let nivel;
        let tarifai;
        let tarifae;
        let tarifap;
        let tarifac;
        let alumbradoa;
        let alumbradop;
        let contribuciona;
        let contribucionp;
        if(this.parametros.C!=null)
        {
        if (this.parametros.nivel=="1"){tarifai=this.parametros.TOTAL1;tarifae=String(parseFloat(this.parametros.TOTAL1)-parseFloat(this.parametros.C));tarifac=this.parametros.D1;tarifap=this.parametros.D1;}
        if (this.parametros.nivel=="12"){tarifai=this.parametros.TOTAL12;tarifae=String(parseFloat(this.parametros.TOTAL12)-parseFloat(this.parametros.C));tarifac=this.parametros.D12;tarifap=this.parametros.D12;}
        if (this.parametros.nivel=="2"){tarifai=this.parametros.TOTAL2;tarifae=String(parseFloat(this.parametros.TOTAL2)-parseFloat(this.parametros.C));tarifac=this.parametros.D2;tarifap=this.parametros.D2;}
        if (this.parametros.nivel=="3"){tarifai=this.parametros.TOTAL3;tarifae=String(parseFloat(this.parametros.TOTAL3)-parseFloat(this.parametros.C));tarifac=this.parametros.D3;tarifap=this.parametros.D3;}
        posit=this.parametros.alumbrado.search("%");
        if (posit>0)
          {
          alumbradoa=parseFloat(tarifai)*(parseFloat((this.parametros.alumbrado.substr(0, posit)))/100);
          alumbradop=parseFloat(tarifac)*(parseFloat((this.parametros.alumbrado.substr(0, posit)))/100);
          }
        else
          {
          alumbradoa=parseFloat(this.parametros.alumbrado);
          alumbradop=parseFloat(this.parametros.alumbrado);
          }
        posit=this.parametros.contribucion.search("%");
        if (posit>0)
          {
          contribuciona=parseFloat(tarifai)*(parseFloat((this.parametros.contribucion.substr(0, posit)))/100);
          contribucionp=parseFloat(tarifac)*(parseFloat((this.parametros.contribucion.substr(0, posit)))/100);
          }
        else
          {
          contribuciona=parseFloat(this.parametros.contribucion);
          contribucionp=parseFloat(this.parametros.contribucion);
          } 
        this.timpact=parseFloat(tarifai)+parseFloat(alumbradoa)+parseFloat(contribuciona);
        this.tpenrea=parseFloat(tarifap)+parseFloat(alumbradop)+parseFloat(contribucionp);
        this.tpencap=0
        this.texpact=parseFloat(tarifae)
        }
        this.tariactual=tarifai
      },

        potencias(valor)
        {
          if (this.posicion==0)
          {
            if (valor==0)
              {
                this.cpotencia=this.cpotencia-1
                if (this.cpotencia<0){this.cpotencia=5}
              }
            if (valor==1)
              {
                this.cpotencia=this.cpotencia+1
                if (this.cpotencia>5){this.cpotencia=0}
              }
          }
          if (this.posicion==1)
          {
            if (valor==0)
              {
                this.gpotencia=this.gpotencia-1
                if (this.gpotencia<0){this.gpotencia=4}
              }
            if (valor==1)
              {
                this.gpotencia=this.gpotencia+1
                if (this.gpotencia>4){this.gpotencia=0}
              }
          }
          this.seleccionargrafica()
        },
          graficar (titulo,seleccion,informacion,tipo,fuente,funcion,variable,factor,info){
          return new Promise((resolve,reject)=>{
          let mesesr = ['Ene', 'Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
          let plabel='';let newDataset=null;let datos=[];let colores=[];let respuesta=[];
          let principal={maximo:0,minimo:0,vmaximo:0,vminimo:0,type: tipo,data: {labels: [],datasets: []},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (seleccion==0 || seleccion==1){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,labelString:info},ticks: {fontSize: fuente}}]}}};
          for (var i =0; i < informacion.data.length; i++) {switch (funcion) {case '0': plabel=String((i+1)*5);break;case '1': plabel=String(i);break;case '2': plabel=String(informacion.data[i].fecha.substring(8,10));break;case '3': plabel=mesesr[Number(informacion.data[i].fecha.substring(5,7))-1];break;case '4': plabel=informacion.data[i].fecha.substring(0,4);break;default:}
          principal.data.labels.push(plabel);
          switch (variable) {
            
            case 0:datos.push((parseFloat(informacion.data[i].v1)*parseFloat(factor)/1000));break;
            case 1:datos.push((parseFloat(informacion.data[i].v3)*parseFloat(factor)/1000));break;
            case 2:datos.push((parseFloat(informacion.data[i].v2)*parseFloat(factor)/1000));break;
            case 3:datos.push((parseFloat(informacion.data[i].v4)*parseFloat(factor)/1000));break;
            case 4:datos.push((parseFloat(informacion.data[i].v5)*parseFloat(factor)/1000));break;
            case 13:datos.push(informacion.data[i].v9);break;
            case 14:datos.push(informacion.data[i].v17);break; 
            case 15:datos.push(informacion.data[i].v18);break; 
            default:
          }
          colores.push("#1B3AF4");
          }
          if(isNaN(datos[datos.length-1]))
          {
            datos.pop()
          }
          if(datos[datos.length-1]=== null)
          {
            datos.pop()
          }
          newDataset = {label:titulo,borderColor:colores,borderWidth: 0.7,data:datos,backgroundColor:"#EAE7E5",display:false};
          principal.data.datasets.push(newDataset)
          var max = Math.max.apply(null,principal.data.datasets[0].data);
          var min = Math.min.apply(null,principal.data.datasets[0].data);
          var plmin=principal.data.datasets[0].data.indexOf(min);
          var plmax=principal.data.datasets[0].data.indexOf(max);
          principal.data.datasets[0].borderColor[plmax]="red";  
          principal.data.datasets[0].borderColor[plmin]="green";
          principal.maximo=principal.data.labels[plmax];
          principal.minimo=principal.data.labels[plmin];
          principal.vmaximo=max;
          principal.vminimo=min;
          resolve (principal)
        });
      },
          graficar1 (titulo1,titulo2,titulo3,seleccion,informacion,tipo,fuente,funcion,variable,factor,info){
          return new Promise((resolve,reject)=>{
          let mesesr = ['Ene', 'Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
          let plabel='';let newDataset=null;let newDataset1=null;let newDataset2=null;let datos=[];let datos1=[];let datos2=[];let colores=[];let colores1=[];let colores2=[];
          let principal={max:[],min:[],pro:[],type: tipo,data: {labels: [],datasets: []},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: false,fontSize: fuente},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (this.posicion==0){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,labelString:info
          },ticks: {fontSize: fuente}}]}}};
          for (var i =0; i < informacion.data.length; i++) {switch (funcion) {case '0': plabel=String((i+1)*5);break;case '1': plabel=String(i);break;case '2': plabel=String(i);break;case '3': plabel=mesesr[Number(informacion.data[i].fecha.substring(5,7))-1];break;case '4': plabel=informacion.data[i].fecha.substring(0,4);break;default:}
          principal.data.labels.push(plabel);
          switch (variable) {
            case 11:
            datos.push(parseFloat(informacion.data[i].v6)*parseFloat(factor));
            datos1.push(parseFloat(informacion.data[i].v7)*parseFloat(factor));
            datos2.push(parseFloat(informacion.data[i].v8)*parseFloat(factor));
            break;
            case 12:            
            datos.push(parseFloat(informacion.data[i].v14)*parseFloat(factor));
            datos1.push(parseFloat(informacion.data[i].v15)*parseFloat(factor));
            datos2.push(parseFloat(informacion.data[i].v16)*parseFloat(factor));
            default:
          }
          colores.push('rgba(0,0,255, 1)');colores1.push('rgba(02,71,171, 1)');colores2.push('rgba(18,10,143, 1)');
          }
          if(isNaN(datos[datos.length-1]))
          {
            datos.pop()
          }
          newDataset = {label:titulo1,borderColor:colores,borderWidth: 0.7,data:datos,backgroundColor:"#EAE7E5",display:false};
          newDataset1 = {label:titulo2,borderColor:colores1,borderWidth: 0.7,data:datos1,backgroundColor:"#EAE7E5",display:false};
          newDataset2 = {label:titulo3,borderColor:colores2,borderWidth: 0.7,data:datos2,backgroundColor:"#EAE7E5",display:false};
          principal.data.datasets.push(newDataset)
          principal.data.datasets.push(newDataset1)
          principal.data.datasets.push(newDataset2)
          for (var i =0; i < 3; i++)
          {
          let sum=0;
          for (let j = 0; j < principal.data.datasets[i].data.length; j++)
          {
            if(isNaN(principal.data.datasets[i].data[j]))
              {
                principal.data.datasets[i].data.pop()
              }
          }
          for (let j = 0; j < principal.data.datasets[i].data.length; j++)
          {
            sum += principal.data.datasets[i].data[j];
          }
          var pro = sum/principal.data.datasets[i].data.length
          var max = Math.max.apply(null,principal.data.datasets[i].data);
          var min = Math.min.apply(null,principal.data.datasets[i].data);
          var plmin=principal.data.datasets[i].data.indexOf(min);
          var plmax=principal.data.datasets[i].data.indexOf(max);
          principal.max[i]=max;
          principal.min[i]=min;
          principal.pro[i]=pro;
          }
          resolve (principal)
        });
      },
      funcion(seleccion)
      {
        for (var i =0; i < 7; i++)
          {
            let $ref = this.$refs['f'+String(i)]
            $ref.style.backgroundColor = '#E6E7EC';
            $ref.style.color = '#000000';
          }
        let $ref = this.$refs['f'+String(seleccion)]
        $ref.style.backgroundColor = '#0E5BD7';
        $ref.style.color = '#ffffff';
        this.posicion=seleccion;
        this.seleccionargrafica()
      }
      },
      data()  {  
        let colores=['rgba(0,0,255, 1)','rgba(02,71,171, 1)','rgba(18,10,143, 1)'] 
        let variable=7;
        return {
          tariactual:0,habilitar:1,pc:0,rf:'Dia',timpact:0,texpact:0,tpenrea:0,treacap:0,gpotencia:0,cpotencia:0,energia:'total',detalle:false,costos:null,switch:false,vpr:false,vd1:false,vd2:false,vd3:false,vd4:false,vd5:false,sensor:0,registro:[],parametros:[],posicion:0,tamano:'00',offsetTop: 0,gano:'',gmes:'',gdia:'',display:'',valormaximo:'',valorminimo:'',dminimo:'0',dmaximo:'0',rminimo:'Mínimo',rmaximo:'Máximo',valortotal:'0',fhora:false,tabla:'2',sufijo:'',prefijo:'',fecha:null,date: null,time:"00:00",es:es,year:false,mes:false,dia:false,hora:false,dialog: false,gr0:null,gr1:null,gr2:null,gr3:null,gr4:null,gr5:null,t1:'',t2:'',t3:'',mx1:'',mx2:'',mx3:'',mn1:'',mn2:'',mn3:'',pr1:'',pr2:'',pr3:'',
          horaconsulta:'00',useHoursOnly: true,
          meses : ['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
          links: ['Hora','Día','Mes','Año','Total',],
          titulo: ['Costos','Energía','Corrientes','Voltajes','Factor de Potencia','Frecuencia','Temperatura',],
          administrador:false,
          itemsd: [],
          editedItem: {},
        }
    },
    mounted () {
      this.tamano=300-(screen.width/11)
      if(screen.width>500){this.pc=1}
        let $ref = this.$refs['f0'];$ref.style.backgroundColor = '#0E5BD7';$ref.style.color = '#ffffff';
        this.getNow(0);
      },
    }
        function sumar_array(array_numeros){
            var suma = 0;
                array_numeros.forEach (function(numero){
                suma += parseFloat(numero);
        });
        return suma;
        }
</script>
<style>
  .v-tabs {
    font-size: 1.2em;
  }
  .custom-time-picker .v-time-picker-title {
  pointer-events: none;
}
</style>