<template>
  <div>
  <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="headers" :items="informacion" sort-by="Nombre" class="elevation-1 table-striped">
    <template v-slot:top>
      <v-toolbar >
        <v-toolbar-title>Días de vigencia</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider><span class="text-h5" :class="theme">{{ diascontrato }}</span>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }"></template>
          <v-card>
            <v-card-title><span>{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.frontera" :items="itemsf" item-text="frontera" item-value="abbr" label="frontera" @change="listaroperadores()"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-dialog ref="inicio" v-model="modali" :return-value.sync="editedItem.fechainicio" persistent width="290px"><template v-slot:activator="{ on, attrs }"><v-text-field v-model="editedItem.fechainicio" label="Fecha Inicio" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field></template><v-date-picker v-model="editedItem.fechainicio" scrollable><v-spacer></v-spacer><v-btn text color="primary" @click="modal = false">Cancel</v-btn><v-btn text color="primary" @click="$refs.inicio.save(editedItem.fechainicio)">OK</v-btn></v-date-picker></v-dialog></v-col>
                  <v-col cols="12" sm="6" md="4"><v-dialog ref="final" v-model="modalf" :return-value.sync="editedItem.fechafin" persistent width="290px"><template v-slot:activator="{ on, attrs }"><v-text-field v-model="editedItem.fechafin" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field></template><v-date-picker v-model="editedItem.fechafin" scrollable><v-spacer></v-spacer><v-btn text color="primary" @click="modal = false">Cancel</v-btn><v-btn text color="primary" @click="$refs.final.save(editedItem.fechafin)">OK</v-btn></v-date-picker></v-dialog></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.operador" :items="itemso" item-text="operador" item-value="abbr" label="Operador" @change="comercializadores()"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.comercializador" :items="itemsc" item-text="comercializadora" item-value="abbr" label="Comercializador"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.niveltension" :items="tens" item-text="nivel" item-value="abbr" label="Nivel tensión"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.mercado" :items="merc" item-text="mercado" item-value="abbr" label="Mercado"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.idcomercializador" label="Idcomercializador"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.circuito" label="Circuito"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.alumbrado" label="Alumbrado"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.contribucion" label="Contribución"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto3" label="Impuesto3"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto4" label="Impuesto4"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto5" label="Impuesto5"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto6" label="Impuesto6"></v-text-field></v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    props: {
    isDark: Boolean
    },
    name: 'Contratos',
    components:{
        Header
    },
     data: () => ({
      editedItem: {impuesto3:'0',impuesto4:'0',impuesto5:'0',impuesto6:'0',inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),},
      modali: false,
      modalf: false,
      dialog: false,
      dialogDelete: false,
      theme: 'green--text',
      diascontrato:'23',
      tens: ['1','1-2','2','3'],
      merc:['Regulado','No regulado'],
      headers: [
        {text: 'Frontera',align: 'start',sortable: false,value: 'frontera',},
        { text: 'Inicio', value: 'fechainicio',sortable: false },
        { text: 'Fin', value: 'fechafin',sortable: false },
        { text: 'Operador', value: 'operador',sortable: false },
        { text: 'Comercializador', value: 'comercializador',sortable: false },
        { text: 'Nivel Tensión', value: 'niveltension', sortable: false },
        { text: 'IDcomercializador', value: 'id', sortable: false },
        { text: 'Circuito', value: 'circuito', sortable: false },
        { text: 'Alumbrado', value: 'alumbrado', sortable: false },
        { text: 'Contribucion', value: 'contribucion', sortable: false },
    
      ],
      informacion: [],editedIndex: -1,
      defaultItem: {},
      select: { comercializador: 'comercializadora'},
      itemso: [],itemsc: [],itemsf: [],
      diascontrato:0,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Contrato' : 'Editar Contrato'
      },
    },

    watch: {
      },
      dialogDelete (val) {
        val || this.closeDelete()
    },

    mounted () {
      this.solicitardatos()
  },

    methods: {

      solicitardatos()
      {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
        };
        axios.post("https://api.coenergia.app/api/lcoenergia",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
            this.diascontrato=''
            this.informacion=data.data.data;
            console.log(this.informacion)
            if(this.informacion.length!=0)
            {
            var day1 = new Date(this.informacion[0].fechafin); 
            const d = new Date();
            let mes=String(d.getMonth()+1)
            let dia=String(d.getDate())
            if (mes.length==1){mes='0'+String(mes)}
            if (dia.length==1){dia='0'+String(dia)}
            var day2 = new Date(String(d.getFullYear())+'-'+String(mes)+'-'+String(dia));
            var difference=(day1-day2);
            this.diascontrato = (difference/(1000 * 3600 * 24))
            if (this.diascontrato<60){this.theme= 'red--text'}
            }
            if (this.diascontrato<0){this.diascontrato='Vencido';this.theme= 'red--text'}
            }
        )
        .catch(
          this.erro_msg = "Validar información",
          )
      },
      editItem (item) {
      },

      deleteItem (item) {
      },

      close () {
      },

      closeDelete () {
      },
            refrescar(valor){
        this.solicitardatos()
      },

  }}


</script>
<style scoped>
  .background-dark {
    background-color: #000;
  }
  .background-light {
    background-color: #fff;
  }
</style>