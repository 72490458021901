    <template>
      <div>
        <v-app-bar class="shrink py-0" color="#1b3af4" fixed elevation="0" dark height="40%">
            <v-toolbar-title>
              <v-img alt="Vuetify Logo" class="shrink mb-0" contain src="../assets/logoeseen.png" transition="scale-transition" width="90"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-on:click="irmenu" icon v-if="$route.name!=='Home' & $route.name!=='Login' & $route.name!=='Menu'"><v-icon>mdi-home</v-icon></v-btn>
            <v-btn v-on:click="salir" icon v-if="$route.name=='Menu'"><v-icon>mdi-logout</v-icon></v-btn>
            <v-btn v-on:click="ingresar" icon v-if="$route.name==='Home'" class="mr-4"><v-icon>mdi-account</v-icon></v-btn>
            <v-spacer></v-spacer>
        </v-app-bar>
      </div>
    </template>

  <script>
  import axios from 'axios';
  export default {
    name: 'Header',
    mounted() {
  },
      data () {
        
        return {
        cliente:null,
        inicial:null,
        listado:false,
        items: [],
        selectedItem:null,
        }
    },
    methods: {

    seleccion(frt){
      this.cliente= ((frt.cliente)).substring(0,15)
      this.listado=false
      localStorage.frontera=this.$CryptoJS.AES.encrypt(frt.frontera, "g3st10n3s33n").toString();
      this.$emit("refrescar",'1')
    },  
    irmenu: function (event) {
      if (this.$route.path != '/Menu') {
        this.$router.push({ path: 'Menu' });
      } 
    },
    listar()
    {
        let prf=this.$CryptoJS.AES.decrypt(localStorage.perfil,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        if (prf==3)
        {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.eseem.co/api/lfronteras",{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
                  this.items=data.data.data
          console.log(this.items)
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      this.listado=true
        }
    },
    salir: function (event) {
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      localStorage.removeItem('perfil');
      localStorage.removeItem('cliente');
      localStorage.removeItem('frontera');
      this.$router.push('/');
    },
    ingresar: function (event,next) {
      if (this.$route.path == '/') {
        this.$router.push({ path: 'Login' });
      } 
    }
  }
  }
</script>
