<template>
  <div>
        <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="headers" :items="informacion" sort-by="Nombre" class="elevation-1">
    <template v-slot:top>
      <v-toolbar >
        <v-toolbar-title>Referencias</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }"><v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Nuevo</v-btn></template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.frontera" :items="itemsf" item-text="frontera" item-value="abbr" label="frontera" @change="listaroperadores()"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-dialog ref="inicio" v-model="modali" :return-value.sync="editedItem.fechainicio" persistent width="290px"><template v-slot:activator="{ on, attrs }"><v-text-field v-model="editedItem.fechainicio" label="Fecha Inicio" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field></template><v-date-picker v-model="editedItem.fechainicio" scrollable><v-spacer></v-spacer><v-btn text color="primary" @click="modal = false">Cancel</v-btn><v-btn text color="primary" @click="$refs.inicio.save(editedItem.fechainicio)">OK</v-btn></v-date-picker></v-dialog></v-col>
                  <v-col cols="12" sm="6" md="4"><v-dialog ref="final" v-model="modalf" :return-value.sync="editedItem.fechafin" persistent width="290px"><template v-slot:activator="{ on, attrs }"><v-text-field v-model="editedItem.fechafin" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field></template><v-date-picker v-model="editedItem.fechafin" scrollable><v-spacer></v-spacer><v-btn text color="primary" @click="modal = false">Cancel</v-btn><v-btn text color="primary" @click="$refs.final.save(editedItem.fechafin)">OK</v-btn></v-date-picker></v-dialog></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.operador" :items="itemso" item-text="operador" item-value="abbr" label="Operador" @change="comercializadores()"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.comercializador" :items="itemsc" item-text="comercializadora" item-value="abbr" label="Comercializador"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.niveltension" :items="tens" item-text="nivel" item-value="abbr" label="Nivel tensión"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.mercado" :items="merc" item-text="mercado" item-value="abbr" label="Mercado"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.idcomercializador" label="Idcomercializador"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.circuito" label="Circuito"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.alumbrado" label="Alumbrado"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.contribucion" label="Contribución"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto3" label="Impuesto3"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto4" label="Impuesto4"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto5" label="Impuesto5"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.impuesto6" label="Impuesto6"></v-text-field></v-col>                  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea borrar esta referencia?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Referencia',
    components:{
        Header
    },
     data: () => ({
      editedItem: {impuesto3:'0',impuesto4:'0',impuesto5:'0',impuesto6:'0',inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),},
      modali: false,
      modalf: false,
      dialog: false,
      dialogDelete: false,
      tens: ['1','1-2','2','3'],
      merc:['Regulado','No regulado'],
      headers: [
        { text: 'Frontera',align: 'start',sortable: false,value: 'frontera',},
        { text: 'Inicio', value: 'fechainicio',sortable: false },
        { text: 'Fin', value: 'fechafin',sortable: false },
        { text: 'Operador', value: 'operador',sortable: false },
        { text: 'Comercializador', value: 'comercializador',sortable: false },
        { text: 'Nivel Tensión', value: 'niveltension', sortable: false },
        { text: 'IDcomercializador', value: 'id', sortable: false },
        { text: 'Circuito', value: 'circuito', sortable: false },
        { text: 'Alumbrado', value: 'alumbrado', sortable: false },
        { text: 'Contribucion', value: 'contribucion', sortable: false },
        { text: 'Impuesto3', value: 'impuesto3', sortable: false },
        { text: 'Impuesto4', value: 'impuesto4', sortable: false },
        { text: 'Impuesto5', value: 'impuesto5', sortable: false },
        { text: 'Impuesto6', value: 'impuesto6', sortable: false },        
        { text: 'Actions', value: 'actions', sortable: false },        
      ],
      informacion: [],editedIndex: -1,
      defaultItem: {},
      select: { comercializador: 'comercializadora'},
      itemso: [],itemsc: [],itemsf: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Referencia' : 'Editar Referencia'
      },
    },

    watch: {
      dialog (val) {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.coenergia.app/api/lfronteras",{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
                  this.itemsf=data.data.data
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
        };
        axios.post("https://api.coenergia.app/api/lreferencia",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
          console.log(data)
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
  },

    methods: {
      editItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "id": this.editedItem.id
        };
        axios.post("https://api.coenergia.app/api/breferencia",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{

            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validarvacio())
        {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        var funcion='sreferencia';
        console.log(this.editedItem)
        if(this.formTitle=='Editar Referencia'){funcion='ereferencia';}
        axios.post("https://api.coenergia.app/api/"+funcion,this.editedItem,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            console.log(data)
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.close()
        }
        else{alert('faltan datos')}
      },
      validarvacio () {
        var estado=true;
        if(this.editedItem.frontera==null){estado=false;}
        if(this.editedItem.fechainicio==null){estado=false;}{this.editedItem.fechainicio=this.editedItem.fechainicio+' 00:00:00'}
        if(this.editedItem.fechafin==null){estado=false;}{this.editedItem.fechafin=this.editedItem.fechafin+' 00:00:00'}
        if(this.editedItem.operador==null){estado=false;}
        if(this.editedItem.comercializador==null){estado=false;}
        if(this.editedItem.niveltension==null){estado=false;}
        if(this.editedItem.mercado==null){estado=false;}
        if(this.editedItem.idcomercializador==null){estado=false;}
        if(this.editedItem.circuito==null){estado=false;}
        if(this.editedItem.alumbrado==null){estado=false;}
        if(this.editedItem.contribucion==null){estado=false;}
        if(this.editedItem.impuesto3==null){estado=false;}
        if(this.editedItem.impuesto4==null){estado=false;}
        if(this.editedItem.impuesto5==null){estado=false;}
        if(this.editedItem.impuesto6==null){estado=false;}        
        return estado;
      },
    salvarfecha (fecha) 
    {
        editedItem.inicio = false
    },
    listaroperadores () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.coenergia.app/api/loperadores",{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            console.log(data.data.data)
            this.itemso=data.data.data
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      },
    comercializadores () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "Operador": this.editedItem.operador
        };
        axios.post("https://api.coenergia.app/api/lcomercializadores",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            this.itemsc=data.data.data
            console.log(data.data.data)
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      },      

  }}


</script>