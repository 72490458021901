<template>
  <div>
        <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="headers" :items="informacion" sort-by="Nombre" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Parametros Técnicos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }"><v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Nuevo</v-btn></template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.frontera" :items="itemsd" item-text="frontera" item-value="abbr" label="frontera"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.capacidad" label="Capacidad Transformador"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.primario" label="Voltaje Primario"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.secundario" label="Voltaje Secundario"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.fases" label="Fases"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.medida" :items="tipomedida" item-text="Tipo medida" item-value="abbr" label="Tipo medida"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-if="editedItem.medida==='Indirecta'" v-model="editedItem.configuracion" :items="configuracion" item-text="Configuración" item-value="abbr" label="Configuración"></v-select></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea borrar este cliente?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Parametros',
    components:{
        Header
    },
     data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'Frontera',align: 'start',sortable: false,value: 'frontera',},
        { text: 'Capacidad', value: 'capacidad' },
        { text: 'Primario', value: 'primario' },
        { text: 'Secundario', value: 'secundario' },
        { text: 'Fases', value: 'fases' },
        { text: 'Medida', value: 'medida', sortable: false },
        { text: 'Configuracion', value: 'configuracion', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },        
      ],
      informacion: [],editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      select: { departamento: 'Ciudad'},
      itemsd: [],itemsc: [],tipomedida: ['Directa','Semidirecta','Indirecta'],configuracion: ['2 elementos interior','2 elementos exterior','3 elementos interior','3 elementos exterior']
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo parametro' : 'Editar Parametros'
      },
    },

    watch: {
      dialog (val) {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.coenergia.app/api/lfronteras",{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
                  this.itemsd=data.data.data
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      console.log(this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8))
        this.Consultar()
  },

    methods: {
      Consultar(){
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let frontera=this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json={"frontera":frontera};
        axios.post("https://api.coenergia.app/api/lparametros",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
          console.log(data)
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      },
      refrescar(valor){
        this.tamano=300-(screen.width/11)
        this.Consultar();
      },
      editItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "id": this.editedItem.id
        };
        axios.post("https://api.coenergia.app/api/bparametros",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{

            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validarvacio())
        {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        var funcion='sparametros';
        if(this.formTitle=='Editar Parametros'){funcion='eparametros';}
        axios.post("https://api.coenergia.app/api/"+funcion,this.editedItem,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.close()
        }
        else{alert('faltan datos')}
      },
      validarvacio () {
        var estado=true;
        if(this.editedItem.frontera==null){estado=false;}
        if(this.editedItem.capacidad==null){estado=false;}
        if(this.editedItem.primario==null){estado=false;}
        if(this.editedItem.secundario==null){estado=false;}
        if(this.editedItem.fases==null){estado=false;}
        if(this.editedItem.medida==null){estado=false;}else
        {
          if(this.editedItem.medida=='Indirecta')
            {
              if(this.editedItem.configuracion==null){estado=false;}
            }
            else
            {
             this.editedItem.configuracion='0'; 
            }
        }
        return estado;
      },
  }}


</script>