<template>
    <div>
        <Header @refrescar="refrescar"/><br>
        <v-row class="d-none d-sm-flex mt-5 pr-5">
          <v-simple-table style="width:100%" v-if="resumen==false" ref="tabla" :fixed-header="true" :height="this.altura">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="item.text[0]==2" class="text-left blue--text font-weight-large" v-for="item in headers">
                 {{item.text}}
                </th>
                <th v-if="item.text[0]!=2" class="text-right" v-for="item in headers">
                 {{item.text}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-right" v-for="(item,i) in headers">
                  <v-icon v-if="i>0" @click="descargar(i)" medium color="green">mdi-download</v-icon>
                </td>
              </tr>
              <tr v-for="(item,i) in contenido">
                <td>
                  {{item.Parametro}}
                </td>
                <td class="text-right" v-if="item.Parametro.length>0" v-for="(contador,j) in facturas">
                   {{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(parseFloat(String(item[j]).replace(/,/g,".")).toFixed(2)))}}
                </td>
                <td v-if="item.Parametro.length<1" v-for="(contador,j) in facturas"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-row>
        <br><v-row>            
          <v-simple-table v-if="resumen==true" style="width:100%"  ref="tabla" :fixed-header="true" :height="this.altura">
          <template v-slot:default>
            <thead>
               <tr>
                <th v-if="item.text.length==3" class="text-left blue--text font-weight-large" v-for="item in headers1">
                 {{item.text}}
                </th>
                <th v-if="item.text.length>3" class="text-right" v-for="item in headers1">
                 {{item.text}}
                </th>
              </tr>           
            </thead>
            <tbody>
             <tr v-for="(item,i) in contenido1">
                <td v-if="item.Mes=='Total'" class="text-left font-weight-bold" >
                  {{item.Mes}}
                </td>
                <td v-if="item.Mes!='Total'" >
                  {{item.Mes}}
                </td>                
                <td v-if="item.Mes!='Total'" class="text-right" v-for="(contador,j) in fechas">
                   ${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(parseFloat(String(item[j]).replace(/,/g,".")).toFixed(0)))}}
                </td>
                <td v-if="item.Mes=='Total'" class="text-right font-weight-bold" v-for="(contador,j) in fechas">
                   ${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(parseFloat(String(item[j]).replace(/,/g,".")).toFixed(0)))}}
                </td>
               </tr>
            </tbody>
          </template>
        </v-simple-table>        
          </v-row>
          <v-container class="mt-8 d-flex d-sm-none">
            <template>
              <v-row dense>
                <v-row v-if="i>0" dense v-for="(item, i) in headers" class="pt-1 pb-3 mt-1"><br><br>
                  <v-card color="white" class="mx-auto" width="350" elevation=1>
                    <v-container fluid width="1000">
                      <v-row><v-col style="text-align:left;width:300" class="text-h7 blue--text">{{item.text}} {{gano}}</v-col><v-col style="text-align:right;width:50"><v-icon @click="descargar(i)" x-large color="green">mdi-download</v-icon></v-col></v-row><v-divider></v-divider>
                        <v-row class='pt-5' dense><v-col style="text-align:center;width: 100%;color:black;" class="text-h5">${{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(facturas[i-1].tfecm))}}</v-col></v-row>
                            <v-row>
                              <v-expansion-panels popout flat class='pr-1'>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>Detalle</v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                  <v-list color="transparent" dense width="330">
                                    <v-list-item v-for="(texto, j) in formato">
                                      <v-list-item-title>{{texto.nombre}}</v-list-item-title><v-list-item-subtitle v-if="texto.nombre.length>0" class="text-right">{{String(Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 10 }).format(String(contenido[j][i-1]).replace(/,/g,".")))}}</v-list-item-subtitle>
                                    </v-list-item>
                                  </v-list>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-row>
                    </v-container>   
                  </v-card><br><br>
                </v-row> 
              </v-row> 
            </template>
          </v-container>
        <div class="text-center">
            <v-dialog width="auto" v-model="year" >
              <div>
                <Datepicker v-model="date" :inline="true" :language="es" minimum-view="year" @input="saveyear()"></Datepicker>
              </div>
            </v-dialog>
          </div><br><br>
              <v-footer color="#1b3af4" fixed padless><v-row justify="center" no-gutters><v-btn class="text-body-2" v-for="link in links" :key="link" color="white" text @click="cambiarfecha(link)">{{ link }}</v-btn></v-row></v-footer>
    </div>
</template>
<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import Header from '@/components/Header.vue';
import {en, es} from 'vuejs-datepicker/dist/locale'
  export default {
    name: 'Menu',
    components:{Header,Datepicker,},
    data () {
        return {
        items: [],
        valores:null,es:es,date: null,gano:'',year:false,anchura:0,altura:0,
        links: ['Año','Resumen',],
        meses : ['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
        headers: [],headers1: [],contenido1: [],facturas:[],contenido: [],formato:[],campo:[],texto:[],estilo:[],fechas:[],resumen:false,
        fechareporte:null,matriz:[],totales:[],filas:['eaicm','eaecm'],
        }
    },
      mounted () {
        this.altura=screen.height-((screen.height*25)/100)
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
          "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8)
        };
        axios.post("https://api.coenergia.app/api/ltra",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
          this.formato=data.data;
          for (var i =0; i < this.formato.length; i++)
          {
            this.campo[i]=this.formato[i].identificacion
            this.texto[i]=this.formato[i].nombre
            this.estilo[i]=this.formato[i].estilo
          }
          this.getNow(0)
        })
        .catch(this.erro_msg = "Validar información",
        )
  },
      methods:{
      cambiarfecha(link){
        switch (link) {case 'Año':this.resumen=false; this.year = true;break;case 'Resumen':this.gano='0000';this.solicitardatos();break;default:}
      },
      refrescar(valor){
        this.getNow(0)       
      },
      saveyear(){this.year = false;this.mesesl="12";this.getNow(1)},
      getNow(flag)
      {
        this.$refs.tabla.$forceUpdate()
        let today='';
        if (flag==0){today=new Date();}else{today=this.date;}
        if (flag==0){this.gano=String(today.getFullYear());}else{this.gano=String(today.getFullYear());}
        this.solicitardatos();
      },
      solicitardatos()
      {
        this.items= [];
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "frontera": this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8),
            "fecha": this.gano,
        };
        axios.post("https://api.coenergia.app/api/atra",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
              this.facturas=data.data
              this.llenartabla()
        })
        .catch(this.erro_msg = "Validar información",
          )
      },

      llenartabla(){
        this.headers=[];this.contenido=[];
        if (this.gano!='0000')
        {
          var datos={};datos['text']=this.gano;datos['value']='Parametro';datos['sortable']=false;datos['align']='center';this.headers.push(datos);
          for (var i =0; i < this.facturas.length; i++)
            {
            var datos={}
            datos['text']=this.meses[parseInt(this.facturas[i].fechainicio.substring(5,7))-1];
            datos['value']=i.toString()
            datos['sortable']=false
            datos['align']='center'
            this.headers.push(datos)
            }
          for (var i =0; i < this.campo.length; i++)
            {  
              var datos={}
              datos['Parametro']=this.texto[i]
              for (var j =0; j < this.facturas.length; j++)
                {
                  if (i==0){datos[j.toString()]=this.facturas[j].eaicm;};if (i==1){datos[j.toString()]=this.facturas[j].eaecm;};if (i==2){datos[j.toString()]=this.facturas[j].ericm;};if (i==3){datos[j.toString()]=this.facturas[j].eripcm;};if (i==4){datos[j.toString()]=this.facturas[j].erecm;}
                  if (i==6){datos[j.toString()]=this.facturas[j].getcm;};if (i==7){datos[j.toString()]=this.facturas[j].cotcm;};if (i==8){datos[j.toString()]=this.facturas[j].petcm;};if (i==9){datos[j.toString()]=this.facturas[j].trtcm;};if (i==10){datos[j.toString()]=this.facturas[j].ditcm;};if (i==11){datos[j.toString()]=this.facturas[j].retcm;};if (i==12){datos[j.toString()]=this.facturas[j].sttcm;};
                  if (i==14){datos[j.toString()]=parseFloat((this.facturas[j].geccm)).toFixed(0);};if (i==15){datos[j.toString()]=parseFloat((this.facturas[j].coccm)).toFixed(0);};if (i==16){datos[j.toString()]=parseFloat((this.facturas[j].peccm)).toFixed(0);};if (i==17){datos[j.toString()]=parseFloat((this.facturas[j].trccm)).toFixed(0);};if (i==18){datos[j.toString()]=parseFloat((this.facturas[j].diccm)).toFixed(0);};if (i==19){datos[j.toString()]=parseFloat((this.facturas[j].reccm)).toFixed(0);};
                  if (i==21){datos[j.toString()]=parseFloat((this.facturas[j].steaicm)).toFixed(0);};if (i==22){datos[j.toString()]=parseFloat((this.facturas[j].steaecm)).toFixed(0);};if (i==23){datos[j.toString()]=parseFloat((this.facturas[j].steripcm)).toFixed(0);};if (i==24){datos[j.toString()]=parseFloat((this.facturas[j].sterecm)).toFixed(0);};
                  if (i==26){datos[j.toString()]=this.facturas[j].cdscm;};if (i==27){datos[j.toString()]=parseFloat((this.facturas[j].ceaicm)).toFixed(0);};if (i==28){datos[j.toString()]=parseFloat((this.facturas[j].ceaecm)).toFixed(0);};if (i==29){datos[j.toString()]=parseFloat((this.facturas[j].ceripcm)).toFixed(0);};if (i==30){datos[j.toString()]=parseFloat((this.facturas[j].cerecm)).toFixed(0);};
                  if (i==32){datos[j.toString()]=this.facturas[j].apcm;};;if (i==33){datos[j.toString()]=parseFloat((this.facturas[j].apeaicm)).toFixed(0);};if (i==34){datos[j.toString()]=parseFloat((this.facturas[j].apeaecm)).toFixed(0);};if (i==35){datos[j.toString()]=parseFloat((this.facturas[j].aperipcm)).toFixed(0);};if (i==36){datos[j.toString()]=parseFloat((this.facturas[j].aperecm)).toFixed(0);};
                  if (i==38){datos[j.toString()]=this.facturas[j].tdl1955tcm;};if (i==39){datos[j.toString()]=this.facturas[j].tdl1955ccm;};if (i==40){datos[j.toString()]=this.facturas[j].tdsycccm;};
                  if (i==42){datos[j.toString()]=this.facturas[j].tcm;};if (i==43){datos[j.toString()]=this.facturas[j].pddcm;};if (i==44){datos[j.toString()]=this.facturas[j].aedmcm;};if (i==45){datos[j.toString()]=this.facturas[j].iacm;};
                  if (i==47){datos[j.toString()]=parseFloat((this.facturas[j].tfsecm)).toFixed(0);};if (i==48){datos[j.toString()]=this.facturas[j].ttsecm;};
                  if (i==50){datos[j.toString()]=this.facturas[j].ccm;};if (i==51){datos[j.toString()]=this.facturas[j].rpmcm;};if (i==52){datos[j.toString()]=this.facturas[j].acm;};if (i==53){datos[j.toString()]=this.facturas[j].cfvcm;};if (i==54){datos[j.toString()]=this.facturas[j].nccm;};if (i==55){datos[j.toString()]=this.facturas[j].ndcm;};if (i==56){datos[j.toString()]=this.facturas[j].occm;};
                  if (i==58){datos[j.toString()]=parseFloat((this.facturas[j].tfecm)).toFixed(0);};
                }
              this.contenido.push(datos)
            }
              this.$refs.tabla.$forceUpdate()
        }
        else
        {
            this.resumen=true
            this.headers1=[]
            this.contenido1=[]
            this.fechas=[]
            this.matriz=[]
            this.totales=[]
            for (var i =0; i < this.facturas.length; i++)
            {
              var yeartitulo=this.facturas[i].fechainicio.substring(0,4)
              if(this.fechas.indexOf(yeartitulo)==-1)
              {
                this.fechas.push(yeartitulo)
              }
            }
            var datos={}
            datos['text']='Mes';
            datos['value']='Mes'
            datos['sortable']=false
            datos['align']='center'
            this.headers1.push(datos)
            for (var i =0; i < this.fechas.length; i++)
            {
              var datos={}
              for (var j =0; j < 12; j++)
              {
              datos[j]=0;
              }
              this.matriz.push(datos)
            }
            for (var i =0; i < this.facturas.length; i++)
            {  
              var fec=this.facturas[i].fechainicio.substring(0,4)
              var pos=this.fechas.indexOf(fec.toString())
              var mon=parseInt(this.facturas[i].fechainicio.substring(5,7))-1
              this.matriz[pos][mon]=this.facturas[i].tfecm
            }
            for (var i =0; i < this.fechas.length; i++)
            {
            var datos={}
            datos['text']=this.fechas[i].toString();
            datos['value']=i.toString()
            datos['sortable']=false
            datos['align']='center'
            this.headers1.push(datos)
            }
            for (var i =0; i < 12; i++)
            {
            var datos={}
            datos['Mes']=this.meses[i];
            for (var j =0; j < this.fechas.length; j++)
            {
              datos[j.toString()]=this.matriz[j][i]
            }
            this.contenido1.push(datos)
            }
            for (var i =0; i < this.matriz.length; i++)
            {
              var total=0
              for (var j =0; j <12; j++)
              {
              total=total+parseFloat(this.matriz[i][j])
              }
              this.totales.push(total)
            }
            var datos={}
            datos['Mes']='Total';
            for (var i =0; i < this.matriz.length; i++)
            {
            datos[i.toString()]=this.totales[i];  
            }
            this.contenido1.push(datos)
            this.$refs.tabla.$forceUpdate()

        }
      },
      descargar(i)
      {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        console.log(this.facturas[i-1].factura)
        let json= {
            "archivo":this.facturas[i-1].factura,
            };
        axios.post("https://api.coenergia.app/api/dfac",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        },
        responseType:"blob",
        })
        .then(data=>{
            this.archivo=data.data;
            let blob = new Blob([data.data], { type:   'application/pdf' } );
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.facturas[i-1].factura;
            this.archivo=link.href;
            link.click();
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
      },
    }
  }


</script>
<style>
table.v-table thead tr th {
  font-size: 10px!important;
}
table.dataTable.dataTable_width_auto {
  width: auto;
}
</style>