<template>
    <div>
        <Header @refrescar="refrescar"/>
          <v-container fluid>
            <v-row class="pt-15 pb-0 ">
                <v-col cols="12" sm="6" md="6" class="d-none d-sm-flex">
                  <img alt="Vue logo" src="../assets/logo.png" :height=this.ancho width="auto">
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-card class="mx-auto" max-width="400" dense elevation=0><br>
                      <v-list class="transparent" dense>
                        <v-list-item><v-list-item-title>Frontera</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.frontera}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Operador de red</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.operador}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Comercializador</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.comercializador}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Nivel de tensión</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.nivel}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Circuito</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.circuito}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Voltaje primario</v-list-item-title><v-list-item-subtitle class="text-right">{{Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(this.parametros.primario)}}V</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Voltaje secundario</v-list-item-title><v-list-item-subtitle class="text-right">{{Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(this.parametros.secundario)}}V</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Subestación principal</v-list-item-title><v-list-item-subtitle class="text-right">{{Intl.NumberFormat('es-MX',{ maximumSignificantDigits: 5 }).format(this.parametros.capacidad)}}kVA,</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Medida</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.medida}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Tipo de medida</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.configuracion}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Medidor</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.modelo}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Serial</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.serial}}</v-list-item-subtitle></v-list-item>
                        <v-list-item><v-list-item-title>Ip Telemedida</v-list-item-title><v-list-item-subtitle class="text-right">{{this.parametros.ip}}</v-list-item-subtitle></v-list-item>
                      </v-list>
                  </v-card>
                </v-col>
            </v-row>
          </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Informacion',
    components:{
        Header
    },
    data () {
      let fuente=12;
      let variable=7;
        return {
        ancho:'',
        parametros:[],
        time: 0,
        mercadobolsa : {type: "line",data: {labels: [],datasets: [{data:[],backgroundColor: "#eeeee4",borderColor:[],borderWidth: 1.2},]},options: {legend:{display:false,},responsive: true,lineTension: 1,scales: {yAxes:[{id: 'y-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,labelString:"Costo en pesos",},ticks: {fontSize: fuente,userCallback: function(value, index, values) {value = value.toString();if (variable==7){value = value.split(/(?=(?:...)*$)/);value = value.join(',');}return value;}}}],xAxes:[{id: 'x-axis-0',display: true,scaleLabel: {display: true,fontSize: fuente,labelString:"Día del mes",},ticks: {fontSize: fuente}}]}}},
        administrador:false,
        itemsd: [],
        editedItem: {},
        }
    },
    mounted () {
          this.refrescar()
     },
     methods:{
    refrescar(valor)
            {
              if (screen.width > 800) {this.ancho="60%";} else {this.ancho="30%";}
              let token=(this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8));
              let frontera=(this.$CryptoJS.AES.decrypt(localStorage.frontera,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8));
              let json={"identificacion":frontera,"medidor":"0000000","fecha":"0000-01-01","tabla":"7","hra":"04"};
              axios.post("https://api.coenergia.app/api/icli",json,{headers: {'Authorization': `Bearer ${token}`}})
              .then(data=>{this.parametros=data.data[0];})
              .catch(this.erro_msg = "Validar información",)

            },

  },
  }


</script>