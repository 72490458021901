<template>
  <div>
    <Header @refrescar="refrescar"/><br><br><br>
  <v-data-table :headers="headers" :items="informacion" sort-by="Nombre" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Clientes</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }"><v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Nuevo</v-btn></template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.cliente" label="Cliente" ></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.frontera" label="Frontera"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.sede" label="Sede"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.nit" label="Nit"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.direccion" label="Direccion"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.pais" label="Pais"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.departamento" :items="itemsd" item-text="departamento" item-value="abbr" label="Departamento" @change="cambiardepartamento"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.ciudad" :items="itemsc" item-text="ciudad" item-value="abbr" label="Ciudad"></v-select></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.primaria" label="Primaria"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.secundaria" label="Secundaria"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.telefono" label="Telefono"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.contacto" label="Contacto"></v-text-field></v-col>
                  <v-col cols="12" sm="6" md="4"><v-text-field v-model="editedItem.email" label="Email"></v-text-field></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea borrar este cliente?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" >Reset</v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
  export default {
    name: 'Clientes',
    components:{
        Header
    },
     data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'Cliente',align: 'start',sortable: false,value: 'cliente',},
        { text: 'Frontera', value: 'frontera' },
        { text: 'Sede', value: 'sede' },
        { text: 'Nit', value: 'nit' },
        { text: 'Direccion', value: 'direccion' },
        { text: 'Pais', value: 'pais', sortable: false },
        { text: 'Departamento', value: 'departamento', sortable: false },
        { text: 'Ciudad', value: 'ciudad', sortable: false },
        { text: 'Actividad Primaria', value: 'primaria', sortable: false },
        { text: 'Actividad Secundaria', value: 'secundaria', sortable: false },
        { text: 'Telefono', value: 'telefono', sortable: false },
        { text: 'Contacto', value: 'contacto', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },        
      ],
      informacion: [],editedIndex: -1,
      editedItem: {pais:'Colombia'},
      defaultItem: {},
      select: { departamento: 'Ciudad'},
      itemsd: [],itemsc: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo cliente' : 'Editar Cliente'
      },
    },

    watch: {
      dialog (val) {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.coenergia.app/api/ldepartamentos",{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
                  this.itemsd=data.data.data
                  if(this.formTitle=="Editar Cliente")
                  {
                    this.cambiardepartamento();
                  }
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        axios.get("https://api.coenergia.app/api/lclientes",{
        headers: {
                'Authorization': `Bearer ${token}`
        }})
        .then(data=>{
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
  },

    methods: {
      editItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.informacion.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "id": this.editedItem.id
        };
        axios.post("https://api.coenergia.app/api/bclientes",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{

            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.validarvacio())
        {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        var funcion='sclientes';
        if(this.formTitle=='Editar Cliente'){funcion='eclientes';}
        axios.post("https://api.coenergia.app/api/"+funcion,this.editedItem,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            this.informacion=data.data.data;
        })
        .catch(
          this.erro_msg = "Validar información",
          )
        this.close()
        }
        else{alert('faltan datos')}
      },
      cambiardepartamento () {
        let token=this.$CryptoJS.AES.decrypt(localStorage.token,"g3st10n3s33n").toString(this.CryptoJS.enc.Utf8);
        let json= {
            "Departamento": this.editedItem.departamento
        };
        axios.post("https://api.coenergia.app/api/lciudades",json,{
        headers: {
                'Authorization': `Bearer ${token}`
        }
        }   )
        .then(data=>{
            this.itemsc=data.data.data
        })
        .catch(
          this.erro_msg = "Validar información",
          )
      },
      validarvacio () {
        var estado=true;
        if(this.editedItem.cliente==null){estado=false;}
        if(this.editedItem.frontera==null){estado=false;}
        if(this.editedItem.sede==null){estado=false;}
        if(this.editedItem.nit==null){estado=false;}
        if(this.editedItem.direccion==null){estado=false;}
        if(this.editedItem.pais==null){estado=false;}
        if(this.editedItem.departamento==null){estado=false;}
        if(this.editedItem.ciudad==null){estado=false;}
        if(this.editedItem.primaria==null){estado=false;}
        if(this.editedItem.secundaria==null){estado=false;}
        if(this.editedItem.telefono==null){estado=false;}
        if(this.editedItem.contacto==null){estado=false;}
        if(this.editedItem.email==null){estado=false;}
        return estado;
      },
      refrescar()
      {},
  }}


</script>