<template>
    <div>
        <v-app-bar class="shrink py-0" color="#1b3af4" fixed elevation="5" dark height="40%">
            <v-toolbar-title>
            <v-img alt="Vuetify Logo" class="shrink mb-0" contain src="../assets/logoeseen.png" transition="scale-transition" width="100"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-on:click="ingresar" icon v-if="$route.name==='Home'" class="mr-8"><v-icon>mdi-account</v-icon></v-btn>
        </v-app-bar><br>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-timeline dense>
                  <v-timeline-item fill-dot class="white--text mb-10" color="blue"><template v-slot:icon></template>
                    <v-text-field hide-details flat label="Ve la energía"></v-text-field></v-timeline-item>
                  <v-timeline-item class="mb-1" color="green" icon-color="grey lighten-2" small>
                    <v-row><v-col cols="12">Consumos Tiempo real.</v-col></v-row>
                  </v-timeline-item>
                  <v-timeline-item class="mb-1" small>
                    <v-row justify="space-between"><v-col cols="12">Facturas en línea.</v-col></v-row>
                  </v-timeline-item>
                  <v-timeline-item class="mb-1" color="grey" small>
                    <v-row justify="space-between"><v-col cols="12">Consumos Históricos</v-col></v-row>
                  </v-timeline-item>
                  <v-timeline-item class="mb-1" color="orange" small>
                    <v-row justify="space-between"><v-col cols="12">Alarmas de consumos</v-col></v-row>
                  </v-timeline-item>
                  </v-timeline>
                </v-col>
              <v-col cols="12" sm="4" md="4">
                  <img alt="Vue logo" src="../assets/logo.png" :height=this.ancho width="auto">
              </v-col>
            </v-row>
          </v-container>
    </div>
</template>

<script>
import "../css/fonts.css"
import heroHomeVue from '@/components/Hero.vue'
import contactHomeVue from '@/components/contact.vue'
import testimonialHomeVue from '@/components/testimonial.vue'
export default {
  name: 'Home',
    components: {heroHomeVue,contactHomeVue,testimonialHomeVue,},
  data: () => ({ancho:'',color: "transparent",colo: "white",}),
  mounted() {if (screen.width > 800) {this.ancho="60%";} else {this.ancho="30%";}},
  methods: {
    ingresar: function (event,next) {
      if (this.$route.path == '/') {
        this.$router.push({ path: 'Login' });
      } 
    }
  },
};
</script>
<style>
.footer{
  padding:0%;
}
@media screen and (max-width:00px) {
 .footer{
  padding:0%;
} 

}
</style>